import PropTypes from "prop-types";
import React, {useState, useEffect, useCallback} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { Grid, Typography, Box, Divider, Paper, Card, CardContent, CardActionArea, CardHeader, CardActions, Button} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { ButtonLoader } from '../../hooks/loader';

import LeftBlock from './forms/leftblock';

import UserFIO from './forms/fio';
import UserMail from './forms/mail';
import UserAccess from './forms/access';
import SelectCity from './forms/city';
import UserPassword from './forms/password';

const General = ({user, setUser}) => {
	let { id } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	const [FIO, setFIO] = useState(
		user.fullname ? user.fullname.split(" ") : {
			0: "", 1: "", 2: ""
		});
	const [password, setPassword] = useState(null);

	useEffect(() => {
		setFIO((user.fullname ? user.fullname.split(" ") : ""));
	}, [user.id]);

	const formSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		axios.post(id ? '/settings/change/admin' : '/settings/change', {
			id: user.id,
			fullname: `${FIO[0]} ${FIO[1]} ${FIO[2]}`,
			email: user.email,
			roles_mask: user.role_id,
			city: user.city,
			cityid: user.cityid,
			old_password: password?.old,
			password: password?.new,
			genpass: password?.gen,
		}).then(res => {
			setLoading(false);
			setPassword(null);
			enqueueSnackbar(res.data.message, { variant: res.data.type });
		}).catch(error => {
			setLoading(false);
			setPassword(null);
		});
	}

	return(
		<Grid container spacing={3}>
			<Grid item xs={3}>
				<LeftBlock user={user} FIO={FIO}/>
			</Grid>

			<Grid item xs={9}>
				<Card variant="outlined">
					<CardHeader title="Профиль" disableTypography={true}/>
					<Divider/>
					<CardContent>
						<form id="changeUserInfo" onSubmit={formSubmit}>
						<Grid container spacing={4}>
							<UserFIO FIO={FIO} setFIO={setFIO} />
							<UserMail user={user} setUser={setUser} />
							<UserAccess user={user} setUser={setUser} />
							<SelectCity user={user} setUser={setUser} />
							<UserPassword password={password} setPassword={setPassword}/>
						</Grid>
						</form>
					</CardContent>
					<Divider/>
					<CardActions style={{padding: "16px"}}>
						<LoadingButton loading={loading} form="changeUserInfo" type="submit" variant="contained" color="primary">
							Сохранить изменения
						</LoadingButton>
					</CardActions>
				</Card>
			</Grid>
		</Grid>
	);
}

General.propTypes = {
    setUser: PropTypes.any,
    user: PropTypes.shape({
        city: PropTypes.any,
        cityid: PropTypes.any,
        email: PropTypes.any,
        fullname: PropTypes.any,
        id: PropTypes.any,
		region: PropTypes.any,
		avatar: PropTypes.any,
        role: PropTypes.any,
        role_id: PropTypes.any
    })
}

export default General;