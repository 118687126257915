import React from 'react';

import { Box, LinearProgress, CircularProgress } from '@mui/material';
import ScaleLoader from "react-spinners/ScaleLoader";
import Grid from '@mui/material/Grid';
import { makeStyles, useTheme } from '@mui/styles';

import { indigo } from '@mui/material/colors';

const useStyles = makeStyles(theme => ({
	center: {
		margin: 0,
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
}));

export const DefaultLoader = () => {
	return(
		<Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "calc(100vh - 120px)" }}
		>
			<Grid item>
				<ScaleLoader
				height={70}
				width={8}
				radius={4}
				margin={4}
				color={indigo[400]}
				//loading={this.state.loading}
				/>
			</Grid>
		</Grid>
	);
}

export const ComponentLoader = ({size}) => {
	const classes = useStyles();
	var circSize = 0;

	switch(size) {
		case "medium": circSize = 36; break;
		case "big": circSize = 48; break;
		default: circSize = 24; break;
	}

	return(
		<Box className={classes.center}>
		<Grid
		container
		spacing={0}
		align="center"
		justifyContent="center"
		direction="column"
		>
			<Grid item>
				<CircularProgress size={circSize} />
			</Grid>
		</Grid>
		</Box>
	);
}

export const ButtonLoader = () => {
	return(
		<CircularProgress color="inherit" size={24} style={{margin: "0 5px"}} />
	);
}