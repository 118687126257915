import { observable, computed, action, autorun } from "mobx";

export var SettingStore = observable({
	headerTransp: false,
	kvartal: Math.floor((new Date().getMonth() + 3) / 3),
	year: new Date().getFullYear(),

	setKvartal: function(val) {
		this.kvartal = val;
	},

	setYear: function(val) {
		this.year = val;
	},

	setHeaderTransp: function(val) {
		this.headerTransp = val;
	}
});