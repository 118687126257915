import React, {useEffect, useState} from 'react';
import { Link as RouterLink, Route } from "react-router-dom";
import axios from 'axios';

import { Paper, Button, CssBaseline, Typography, Link, Box, Container,
	FormControl, InputLabel, Select, MenuItem, Collapse, Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {GitHub} from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
	paper: {
		display: 'flex',
		minHeight: '100vh',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	logo: {
		width: 70,
		height: 70,
	}
}));

export default ({children, ...props}) => {
	const classes = useStyles();

	return (
	<Container component={Box} maxWidth="sm" className={classes.paper}>
		<Box m="auto">
			<CssBaseline />

			<Box component={Paper} variant="outlined" sx={{p: 5, bgcolor: 'inherit', borderRadius: 3}}>
				<Box sx={{ display: 'flex', justifyContent: 'center', }}>
					<img src="/assets/img/logo-finam.png" alt="лого" className={classes.logo} />
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
					<Typography component="h1" variant="h5" gutterBottom>
						{props.textBlock}
					</Typography>
				</Box>

				{children}
			</Box>

		<Route path="/login">
			<Box mt={2}>
				<GotoBoard />
			</Box>
		</Route>
		<Box mt={6}>
			<Copyright />
		</Box>
		</Box>
	</Container>
	);
}

const GotoBoard = () => {
	const classes = useStyles();
	const [cities, setCities] = useState(null);

	useEffect(() => {
		axios.get("/cities/finam").then(res => {
			setCities(res.data);
		});
	}, []);

	const handleClick = (e) => {
		window.location.replace('/board/currency/' + e.target.value);
	}

	return(
		<FormControl className={classes.form}>
			<InputLabel id="demo-simple-select-label">Перейти на табло</InputLabel>
			<Select
			labelId="demo-simple-select-label"
			id="demo-simple-select"
			value=""
			onChange={handleClick}
			>
				{cities?.map((row, index) => (
					<MenuItem key={row.id} value={row.id}>{row.city}</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

export const ErrorHandler = ({status}) => {
	return(
		<Collapse in={status && status.type != "success"}>
			<Box width='100%' my={1}>
				<Alert severity="error">{status?.message}</Alert>
			</Box>
		</Collapse>
	);
}

function Copyright() {
	return (
	<Typography variant="body2" color="textSecondary" align="center">
		{'Copyright © ' + new Date().getFullYear()}<br/>
		{'Created with ❤️️ by '}
		<Link color="secondary" href="https://github.com/MichaelKheel"><GitHub style={{fontSize: "1em"}}/> MichaelKheel</Link>
	</Typography>
	);
}