import React from 'react';
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material';
import { FindInPage } from '@mui/icons-material';

export const ComponentEmpty = ({data, children}) => {
	if(data !== null && data.length > 0) return children;

	return(
		<Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'GrayText'}}>
			{data === null ? <CircularProgress /> :
			<>
			<Box>
				<FindInPage fontSize="large"/>
			</Box>
			<Typography variant="subtitle2">Здесь пока нет объектов ¯\_(ツ)_/¯</Typography>
			</>
			}
		</Box>
	);
}