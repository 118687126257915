import PropTypes from "prop-types";
import React, {useState, useEffect} from 'react';
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import axios from 'axios';

import { Button, Typography, TextField, Box, Backdrop, CircularProgress, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';

import AuthTemplate, {ErrorHandler} from './template';
import NewPassForm from './newpass';

const useStyles = makeStyles(theme => ({
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	alert: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
}));

export default () => {
	const [reset, setReset] = useState(true);
	const [status, setStatus] = useState("");

	let query = new URLSearchParams(useLocation().search);

	useEffect(() => {
		if(query.get("selector") && query.get("token"))	{

			axios.post('/auth/resetpass/checktoken', {
				selector: query.get("selector"),
				token: query.get("token"),
			}).then(res => {
				setStatus(res.data);
				setReset(false);
			});

		} else setReset(false);
	}, []);

	if(reset) return <Backdrop open={true}><CircularProgress color="inherit" /></Backdrop>;
	if(status.type == "success") return <NewPassForm query={query} />;
	if(status.type == "danger") return <ResetPasswordError status={status} />;

	return <ResetPassword />;
}

const ResetPasswordError = ({status}) => {
	const classes = useStyles();
	const history = useHistory();

	return(
		<AuthTemplate textBlock="Сброс вашего пароля">
			<Box className={classes.alert}>
				<Alert severity="error">{status.message}</Alert>
			</Box>
			<Button fullWidth variant="contained" className={classes.submit} onClick={() => history.push("/login")}>Вернуться</Button>
		</AuthTemplate>
	);
}

const ResetPassword = () => {
	const classes = useStyles();
	const history = useHistory();
	const [email, setEmail] = useState(null);
	const [status, setStatus] = useState(null);
	const [load, setLoad] = useState(false);

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoad(true);
		setStatus(null);
		const res = await axios.post('/auth/resetpass/sendmail', {email: email});
		setStatus(res?.data);
		setLoad(false);
	}

	return(
		<AuthTemplate textBlock="Сброс вашего пароля">
			<ErrorHandler status={status} />
			<Typography variant="subtitle2">
				{status?.type == "success" ?
				'Проверьте свою электронную почту, чтобы найти ссылку для сброса пароля. Если он не появится в течение нескольких минут, проверьте папку со спамом.' :
				'Введите адрес электронной почты вашей учетной записи, и мы вышлем вам ссылку для сброса пароля.'}
			</Typography>
			{status?.type == "success" ?
			<Button fullWidth variant="contained" className={classes.submit} onClick={() => history.push("/login")}>Вернуться к авторизации</Button> :
			<form className={classes.form} onSubmit={onSubmit}>
				<TextField
				variant="outlined"
				size="small"
				margin="normal"
				required
				fullWidth
				id="email"
				label="Email Address"
				type="email"
				autoComplete="new-password"
				onChange={(e) => setEmail(e.target.value)}
				/>
				<LoadingButton
				fullWidth
				loading={load}
				variant="contained"
				type="submit"
				className={classes.submit}
				>
					Сбросить пароль
				</LoadingButton>
			</form>
			}
		</AuthTemplate>
	);
}