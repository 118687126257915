import React, {useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {observer} from 'mobx-react-lite';

import { AppBar, Divider, IconButton, Toolbar, Box, Collapse, Alert } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {useWindowScroll} from 'react-use';

import { AuthAccess } from 'hooks/access';
import { useStores } from '@store';

import NotificationIcon from './notification';
import ChangeOffice from './office';
import ProfileMenu from './profile';
import SaveUrl from 'modules/saveurl/menu';
import Adds from './adds';
import ShareAPI from './api';
import ReleasesGithub from './releases';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
	appBar: {
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
		},
		transition: theme.transitions.create(['background'], { duration: 300 }),
	},
	appBarTrans: {
		backgroundColor: 'transparent',
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
}));

export default observer(({handleDrawer}) => {
	const classes = useStyles();
	const {settings} = useStores();

	const {y} = useWindowScroll();
	const location = useLocation();
	const current = location.pathname.split('/');
	const route = current[1];
	const transparent = (route == "city") && y == 0 ? true : false;

	useEffect(() => {
		settings.setHeaderTransp(transparent);
	}, [transparent]);

	return (
        <AppBar color="inherit" elevation={0} position="fixed" className={`${classes.appBar} ${transparent && classes.appBarTrans}`}>
			<Toolbar variant="dense">
				<IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawer}
                    className={classes.menuButton}
                    size="large">
					<MenuIcon />
				</IconButton>
				<ReleasesGithub />
				<AuthAccess access="Controller">
					<ShareAPI />
				</AuthAccess>
				<WebsocketStatus />
				<NotificationIcon />
				{/* <SaveUrl /> */}
				<Adds />
				<ChangeOffice />
				<ProfileMenu />
			</Toolbar>
			<Divider />
		</AppBar>
    );
});

const WebsocketStatus = observer(() => {
	const {ws} = useStores();

	return(
		<Box flexGrow={1}>
			<Collapse in={ws.status != 1 && ws.status != null}>
				<Alert severity="error">Ошибка соединения</Alert>
			</Collapse>
		</Box>
	);
});