import { types, flow } from "mobx-state-tree";

const BankValue = types.model({
	bank: types.string,
	value: types.number,
});

const FirstEntry = types.model({
	0: types.maybe(BankValue),
	1: types.maybe(BankValue),
	2: types.maybe(BankValue),
	3: types.maybe(BankValue),
	4: types.maybe(BankValue),
	5: types.maybe(BankValue),
	second: types.maybe(BankValue),
	source: types.string,
	type: types.string,
});

export const BestEntry = types.model({
	bUSD: types.maybeNull(FirstEntry),
	sUSD: types.maybeNull(FirstEntry),
	bEUR: types.maybeNull(FirstEntry),
	sEUR: types.maybeNull(FirstEntry),
	bCNY: types.maybeNull(FirstEntry),
	sCNY: types.maybeNull(FirstEntry),
})
.views(self => ({

}))
.actions(self => ({

}));