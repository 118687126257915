import React from "react";

import TimeAgo from 'react-timeago';
import LocaleRU from 'react-timeago/lib/language-strings/ru';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
const formatter = buildFormatter(LocaleRU);
import {Box} from '@mui/material';

const Timeago = (props) => {
	const {date, title, end, timezone, ...box} = props;

	return(
		<Box component="small" color="text.disabled" fontWeight="bold" {...box}>
			{date && <TimeAgo
			date={timezone ? getTime(date, timezone) : date}
			formatter={formatter}
			title={title} />
			}
		</Box>
	);
}

function getTime(date, timezone) {
	var tzDate = new Date(date);
	var tzlocal = -tzDate.getTimezoneOffset() / 60;
	tzDate.setHours(tzDate.getHours() + tzlocal);
	var newDate = tzDate.toISOString().replace('Z', `+${timezone < 10 ? "0" + timezone : timezone}:00`);

	return new Date(newDate);
}

export default Timeago;