import axios from "axios";
import { flow, types } from "mobx-state-tree";
import { Websocket } from './websocket';

axios.defaults.baseURL = '/api';

export const User = types
	.model("User", {
		id: types.number,
		city: types.maybeNull(types.string),
		cityid: types.maybeNull(types.number),
		email: types.string,
		last_login: types.maybeNull(types.string),
		roles_mask: types.number,
		status: types.number,
		username: types.maybeNull(types.string),
		avatar: types.string,
		registered: types.maybeNull(types.string),
		hide_type: types.optional(types.boolean, false),
		hide_city: types.maybeNull(types.array(types.number))
	})
	.views(self => ({
		get onlyName() {
			let name = self.username.split(" ");
			return name[1];
		},
        get shortName() {
            let name = self.username.split(" ");

            return name ? `${name[1]} ${name[0].substr(0, 1)}.` : 'n/a';
        },
		get userName() {
			let name = self.username.split(" ");
			return name ? `${name[0]} ${name[1]}` : 'неизвестно';
		},
		get viewRole() {
			let role = "";

			switch(self.roles_mask) {
				case 1: role = "Разработчик";break;
				case 512: role = "Руководитель";break;
				case 4: role = "Куратор";break;
				case 65536: role = "Контроллер";break;
				case 2097152: role = "Кассир";break;
				case 131072: role = "Сайт";break;
				case 2048: role = "Получатель";break;
			}

			return role;
		},
		get viewRegistered() {
			let date = new Date(self.registered);

			return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
		},
		get viewLastLogin() {
			let date = new Date(self.last_login);

			return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
		},
		get viewAvatar() {
			return self.avatar ? `${process.env.IMG}/assets/img/avatars/${self.avatar.split('.').join('_thumb.')}` : null;
		}
	}))
	.actions(self => ({
		setStatus(status) {
			self.status = status || 0;
		},
		setValue(key, value) {
			self[key] = value;

			let data = {
				userid: self.id,
				name: key,
				value: value,
			};

			axios.post('/user/edit/value', data).then(res => {
				res?.data.success && Websocket.send('user/edit/value', data);
			});
		},
	}));

export const Users = types
	.model("Users", {
		data: types.array(User),
		isLoading: types.optional(types.boolean, true),
	})
	.views(self => ({
		findUser(id) {
			return self.data.find(item => item.id == id);
		},
		findOnlineInCity(city) {
			return self.data.filter(item => item.city?.startsWith(city));
		},
	}))
	.actions(self => {
		const fetchData = flow(function* fetchData() {
			self.setLoading(true);
			const response = yield axios.get("/user/list/all");
			self.data = response.data;
			self.addRobot();
			self.setLoading(false);
			Websocket.send('online', {success: true});
		});

		function setLoading(bool) {
			self.isLoading = bool;
		}

		const addRobot = () => {
			self.data.push({
				id: 0,
				email: "server@projecttomskplus.ru",
				username: "Шелезяка Робот Васильевич",
				roles_mask: 0,
				status: 0,
				avatar: `${process.env.IMG}/assets/img/robot.png`,
			});
		}

		function wsUpdate() {
			if(self.isLoading) return;

			Websocket.bind('users/online', (data) => {
				data.map(item => {
					const user = self.findUser(item.userid);
					user?.setStatus(item.status);
				});
			});

			Websocket.bind('user/add', (data) => {
				self.fetchData();
			});

			Websocket.bind('user/del', (data) => {
				self.data = self.data.filter(item => item.id != data.userid);
			});

			Websocket.bind('user/f5', (data) => {
				window.location.reload();
			});
		}

		return { fetchData, setLoading, wsUpdate, addRobot }
	});

export const UsersStore = Users.create({});