import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import {Container, Grid, Typography, Box, Divider, IconButton, TextField, InputAdornment} from '@mui/material';
import {Visibility, VisibilityOff, Loop} from '@mui/icons-material';

import { useStores } from '@store';

export default observer(({password, setPassword}) => {
	const { auth } = useStores();
	const [showpass, setShowpass] = useState(false);

	const InputProps = {
		endAdornment: (
			<InputAdornment position="end">
				<IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowpass(!showpass)}
                    edge="end"
                    size="large">
				{showpass ? <Visibility /> : <VisibilityOff />}
				</IconButton>
				{auth.Leader &&
					<IconButton
                        aria-label="generation password"
                        onClick={() => {
                            const generate = GenericPassword();
                            setPassword({...password, new: generate, gen: generate})}
                        }
                        edge="end"
                        size="large">
						<Loop />
					</IconButton>
				}
			</InputAdornment>
		)
	};
	return(
		<>
		<Grid item xs={12}>
			<Typography variant="h6" gutterBottom>Смена пароля</Typography>
			<Divider/>
		</Grid>
		{!auth.Leader &&
		<Grid item xs={6}>
			<TextField
				label="Старый пароль"
				variant="outlined"
				type={showpass ? 'text' : 'password'}
				InputProps={InputProps}
				fullWidth
				autoComplete="new-pass1"
				value={password?.old || ""}
				onChange={(e) => setPassword({...password, old: e.target.value})}
			/>
		</Grid>
		}
		<Grid item xs={6}>
			<TextField
				label="Новый пароль"
				variant="outlined"
				type={showpass ? 'text' : 'password'}
				InputProps={InputProps}
				fullWidth
				autoComplete="new-pass2"
				value={password?.new || ""}
				onChange={(e) => setPassword({...password, new: e.target.value})}
			/>
		</Grid>
		</>
	);
});

function GenericPassword(length = 10) {
	var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*+<>ABCDEFGHIJKLMNOP1234567890";
	var pass = "";
	for (var x = 0; x < length; x++) {
		var i = Math.floor(Math.random() * chars.length);
		pass += chars.charAt(i);
	}
	return pass;
}