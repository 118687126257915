import PropTypes from "prop-types";
import React, {useState, useEffect, useCallback} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {observer} from "mobx-react-lite";
import { useSnackbar } from 'notistack';

import {
	Container, Grid, Typography, Box, Divider, Paper, Card, CardContent, CardActionArea, CardHeader, CardActions, Avatar, Button, IconButton, TextField,
	FormControl, InputLabel, OutlinedInput, InputAdornment, Select, Menu, MenuItem, List, ListItem, ListItemText, ListItemIcon,
} from '@mui/material';
import {Visibility, VisibilityOff, Loop, AddLocation, Person, VerifiedUser, Mail, LocationCity} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import AvatarUploader from './avatar';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		'& > *': {
			margin: theme.spacing(1),
		},
	},
	cardCenter: {
		display: "flex",
		textAlgin: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	large: {
		width: theme.spacing(9),
		height: theme.spacing(9),
		fontSize: "2.5rem",
	},
}));

export default ({user, FIO}) => {
	const classes = useStyles();

	return(
		<Card variant="outlined">
			<CardContent className={classes.cardCenter}>
				<CardActionArea>
					<AvatarUploader userid={user.id} avatar={user.avatar}/>
				</CardActionArea>
				<List dense disablePadding>
					<ListItem disableGutters>
						<ListItemIcon><Person /></ListItemIcon>
						<ListItemText primary={FIO ? FIO[0] + ' ' + FIO[1] : "Новый пользователь"} />
					</ListItem>
					<ListItem disableGutters>
						<ListItemIcon><Mail /></ListItemIcon>
						<ListItemText primary={user.email} />
					</ListItem>
					{/* <ListItem disableGutters>
						<ListItemIcon><VerifiedUser /></ListItemIcon>
						<ListItemText primary={user.role} />
					</ListItem> */}
					<ListItem disableGutters>
						<ListItemIcon><LocationCity /></ListItemIcon>
						<ListItemText primary={user.city || "Город неопределен"} secondary={user.region || "Регион не определен"} />
					</ListItem>
				</List>
			</CardContent>
		</Card>
	);
}