import { createContext } from 'react';
import { observable } from "mobx";

import { Websocket } from './websocket';
import { Auth } from "./auth";
import { SettingStore } from './settings';
import { ChatStore } from "./chat";
import { NotifyStore } from "./notification";
import { UsersStore } from "./users";
import { CitiesStore } from "./cities/cities";
import { QuotesStore } from './quotes';

var BreadCrumps = observable([]);
//var Mentions = observable([]);

export const storesContext = createContext({
	auth: Auth,
	authStore: Auth,
	settings: SettingStore,
    quotes: QuotesStore,
	//mentions: Mentions,
	ws: Websocket,
	BreadCrumps: BreadCrumps,
	chat: ChatStore,
	cities: CitiesStore,
	notify: NotifyStore,
	users: UsersStore,
});