import React, {useState} from 'react';
import { Link as RouterLink } from "react-router-dom";
import axios from 'axios';

import { TextField, FormControlLabel, Checkbox, Link, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';

import AuthTemplate, {ErrorHandler} from './template';

const useStyles = makeStyles(theme => ({
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
}));

export default () => {
	const classes = useStyles();

	const [email, setEmail] = useState(null);
	const [pass, setPass] = useState(null);
	const [remember, setRemember] = useState(0);
	const [status, setStatus] = useState(null);
	const [load, setLoad] = useState(false);

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoad(true);
		setStatus(null);

		const res = await axios.post('/auth/login', {
			email: email,
			password: pass,
			remember: remember,
		});

		setStatus(res?.data);
		setLoad(false);
		res?.data.type == "success" && window.location.replace("/");
	}

	return (
	<AuthTemplate textBlock="Вход в систему">
		<ErrorHandler status={status} />
		<form className={classes.form} onSubmit={onSubmit}>
			<TextField
			variant="outlined"
			//size="small"
			margin="normal"
			required
			fullWidth
			label="Адрес эл. почты"
			name="email"
			onChange={(e) => setEmail(e.target.value)}
			/>
			<TextField
			variant="outlined"
			margin="normal"
			//size="small"
			required
			fullWidth
			name="password"
			label="Password"
			type="password"
			autoComplete="authPass"
			onChange={(e) => setPass(e.target.value)}
			/>
			<Box display="flex">
				<Box flexGrow={1}>
					<FormControlLabel
					control={<Checkbox name="remember" color="primary" onChange={(e) => setRemember(e.target.checked)} />}
					label="Запомнить меня"
					/>
				</Box>
				<Box py={1}>
					<Link component={RouterLink} to="/recoverypass">Забыли пароль?</Link>
				</Box>
			</Box>
			<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
				<LoadingButton
				loading={load}
				type="submit"
				variant="contained"
				color="primary"
				size="large"
				>
					Войти
				</LoadingButton>
			</Box>
		</form>
	</AuthTemplate>
	);
}