import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

import { Button, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(theme => ({
	root: {
		textAlign: "center",
	},
}));

export const Error404 = () => {
	const classes = useStyles();

	return(
		<Box className={classes.root}>
			<Typography variant="h4">404: Страницы, которую вы ищете, здесь нет</Typography>
			<Typography variant="subtitle1">Вы либо пробовали какой-то темный маршрут, либо пришли сюда по ошибке. Что бы это ни было, попробуйте использовать навигацию</Typography>
			<Box display="flex" my={6} justifyContent="center">
				<img src="/assets/img/error404.gif" />
			</Box>
			<Button variant="outlined" color="primary" component={RouterLink} to="/">Вернуться назад</Button>
		</Box>
	);
}

export const Error403 = () => {
	const classes = useStyles();

	return(
		<Box className={classes.root}>
			<Typography variant="h4">Error 403! Access Denied.</Typography>
			<Typography variant="subtitle1">Эта страница не доступна вам, так как, ваших прав не достаточно.</Typography>
			<Box display="flex" my={6} justifyContent="center">
				<img src="/assets/img/error403.gif" />
			</Box>
			<Button variant="outlined" color="primary" component={RouterLink} to="/">Вернуться назад</Button>
		</Box>
	);
}