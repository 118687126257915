import axios from "axios";
import { flow, types } from "mobx-state-tree";
import { Websocket } from './websocket';

//axios.defaults.baseURL = '/api';

const Quote = types
    .model("Quote", {
        id: types.number,
        type: types.string,
        price: types.number,
        val2: types.number,
        val3: types.number,
        diff: types.number,
        date: types.string,
    })
    .views(self => ({

    }))
    .actions(self => ({

    }));

export const Quotes = types
    .model("Quotes", {
        data: types.array(Quote),
    })
    .views(self => ({
        findQuote(type) {
            return self.data.find(item => item.type == type);
        }
    }))
    .actions(self => {
        const fetchData = flow(function* fetchData() {
            const response = yield axios.get("/admin/quotes");
            self.data = response.data;
            //self.isLoading = false;
        })

        function wsUpdate() {
            Websocket.bind('quote/update', (data) => {
                self.data = data;
            });
        }

        return { fetchData, wsUpdate }
    });

export const QuotesStore = Quotes.create({});