import { observable, computed, action, autorun } from "mobx";
import axios from "axios";
import store from 'store';

export var ChatStore = observable({
	// Chat Settings
	drag: store.get('chat-drag') || false,
	appearance: {
		fontsize: store.get('chat-visual')?.fontsize || 11,
		nameformat: store.get('chat-visual')?.nameformat || false,
		dateformat: store.get('chat-visual')?.dateformat || false,
	},
	setAppearance: function(type, value) {
		this.appearance[type] = value;
		store.set('chat-visual', this.appearance);
	},
	// Chat Message
	selectMsg: null,
	reply: null,
	setDrag: function() {
		this.drag = !this.drag;
		store.set('chat-drag', this.drag);
	},
	resetSelect: function() {
		this.selectMsg = null;
		this.reply = null;
	},
});