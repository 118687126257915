import React, {useState} from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { Container, Button, Box, Fade, Modal } from '@mui/material';

import Settings from 'pages/settings/index';

const style = {
	position: 'absolute',
	width: '100%',
	height: '100%',
	bgcolor: 'background.paper',
	p: 4,
};

export const SettingsRoute = () => {
	let location = useLocation();
	let history = useHistory();
	const [open, setOpen] = useState(location.pathname == "/settings" ? true : false);

	const handleClose = (e) => {
		e.stopPropagation();
		setOpen(false);
		history.goBack();
	}

	return(
		<Modal
		aria-labelledby="transition-modal-title"
		aria-describedby="transition-modal-description"
		open={open}
		onClose={handleClose}
		closeAfterTransition
		hideBackdrop
		className={open ? "modal" : "modal-out"}
		>
			<Fade in={open}>
				<Box sx={style}>
					<Container fixed>
						<Button onClick={handleClose}>lol</Button>
						<Settings />
					</Container>
				</Box>
			</Fade>
		</Modal>
	);
}