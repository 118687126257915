import { Autocomplete, CircularProgress, TextField, Typography } from '@mui/material';
import { axios, cancelAxios } from "api";
import React, { FunctionComponent, useEffect, useState } from 'react';

export const FinamCities: FunctionComponent<typeof Autocomplete> = props => {
	const {value, onlyCity, ...autoComp} = props;
	const [cities, setCities] = useState([]);
	const [open, setOpen] = useState(false);
	const [load, setLoad] = useState(null);

	useEffect(() => {
		if(cities.length === 0) {
			if(value !== undefined || open) {
				setLoad(true);
				axios.get('/cities/finam').then(res => {
					setCities(res.data);
					setLoad(false);
				});
			}
		}

		return () => {cancelAxios}
	}, [open]);

	return(
	<Autocomplete
		value={cities.find(item => item.id === value) || null}
		options={onlyCity ? cities.filter(item => item.city.startsWith(onlyCity.replace(/[\d]/g, ''))) : cities}
		disableClearable={true}
		loading={load}
		open={open}
		onOpen={() => setOpen(true)}
		onClose={() => setOpen(false)}
		//groupBy={option => option.region}
		getOptionLabel={option => option.city}
		renderOption={(props2, option, { selected }) => (
			<li {...props2}>
				{option.city} (<Typography variant="caption" color="GrayText">{option.address}</Typography>)
			</li>
		)}
		{...autoComp}
		//value={geo.find(item => item.city == user.city) || null}

		renderInput={params => (
			<TextField
			{...params}
			inputProps={{
				...params.inputProps,
				autoComplete: "off",
			}}
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<>
					{load ? <CircularProgress size="1.5rem" /> : null}
					{params.InputProps.endAdornment}
					</>
				),
			}}
			label="Офис"
			variant="outlined"
			fullWidth
			/>
		)}
	/>
	);
}