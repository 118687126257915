import axios from "axios";
import { toJS } from 'mobx';
import { flow, types } from "mobx-state-tree";
import { Auth } from "../auth";
import { Websocket } from '../websocket';
import { AutoRobotEntry } from './autorobot';
import { BalanceEntry } from './balance';
import { BestEntry } from './best';
import { BoardEntry } from './board';
import { CashboxLimitsEntry } from './cashbox';
import { CurrencyEntry } from './currency';
import { FinalCurrencyEntry } from './finalcurrency';
import { LimitsEntry } from './limits';
import { RobotEntry } from './robot';


export const CityEntry = types.model("CityEntry", {
		id: types.number,
		city: types.string,
		city_en: types.maybe(types.string),
		address: types.string,
		GMT: types.number,
		id_kov: types.string,
		id_excur: types.number,
		id_bankiru: types.string,
		bankiru: types.boolean,
		usersite: types.boolean,
		bestcurr: types.maybeNull(types.string),
		bestcurr_alt: types.maybeNull(types.model({
			name: types.string,
			url: types.string,
		})),
		cny: types.boolean,
		// rbk: types.boolean,
		// Связанные объекты
		limits: LimitsEntry,
		cashbox: CashboxLimitsEntry,
		balance: BalanceEntry,
		currency: CurrencyEntry,
		best: BestEntry,
		robot: RobotEntry,
		autorobot: AutoRobotEntry,
		board: BoardEntry,
		finalcurr: FinalCurrencyEntry,
	})
	.views(self => ({
		get CityName() {
			return self.city.replace(/[\d]/g, '');
		},
		get pageBG() {
			return `/assets/img/cities/${encodeURI(self.CityName.toLowerCase())}.jpg`
		},
		get dateUTC() {
			var now = new Date();
			var utc = self.GMT - (now.getTimezoneOffset() / 60 * -1);
				now.setHours(now.getHours() + utc);

			return {utc: utc, now: now};
		},
	}))
	.actions(self => ({
		setValue: (type, value) => {
			self[type] = value;
		},
	}));

export const Cities = types
	.model("Cities", {
		data: types.array(CityEntry),
		isLoading: types.optional(types.boolean, true)
	})
	.views(self => ({
		findCity(id) {
			return self.data.find(item => item.id === id);
		},
		findCityName(city) {
			return self.data.find(item => item.city == city);
		},
		// city = cityname
		offices(city) {
			return self.data.filter(item => item.city.startsWith(city?.replace(/[\d]/g, '')));
		},
		hide() {
			return self.data.filter(item => Auth.hide_type ? Auth.hidecity.includes( item.id ) == 1 : Auth.hidecity.includes( item.id ) != 1);
		},
	}))
	.actions(self => {
		const fetchData = flow(function* fetchData() {
			if(self.isLoading) {
				const response = yield axios.get("/cities/alldata");
				self.data = response.data;
				self.setLoading(false);
			}
		});

		function setLoading(bool) {
			self.isLoading = bool;
		}

		function deleteCity(id) {
			const filter = self.data.filter(item => item.id !== id);
			self.data = filter;
		}

		function wsUpdate(glData) {
			if(self.isLoading) return;
			var city = self.findCity(glData.id ? glData.id : glData.cityid);

			// Настройка города
			Websocket.bind('city/change', (data) => {
				city.setValue(data.name, data.value);
			});
			Websocket.bind('city/add', (data) => {
				self.setLoading(true);
				self.fetchData();
			});
			// DANGER Удаление офиса
			Websocket.bind('city/delete', (data) => {
				self.deleteCity(data.cityid);
			});
			// Обновление курсов через инпуты на странице
			Websocket.bind('currency/inputs', (data) => {
				city.currency.setCurrencyInput(data);
			});

			// Обновление курсов через инпуты в выдвижном меню
			Websocket.bind('currency/inputs-select', (data) => {
				data.ids.map(cityid => {
					let _city = self.findCity(+cityid);
					_city.currency.setCurrencyInput(data);
				});
			});

			// Robot change
			Websocket.bind('currency/robot', (data) => {
				city.robot.setValue(data.name, data.value);
			});
			Websocket.bind('currency/robot-select', (data) => {
				data.ids.map(cityid => {
					let _city = self.findCity(+cityid);
					_city.robot.setValue(data.name, data.value);
				});
			});
			// Autorobot change /city/<>
			Websocket.bind('autorobot/change', (data) => {
				city.autorobot.setValue(data.name, data.value);
			});
			// Autorobot cmd change
			Websocket.bind('autorobot/enable', (data) => {
				city.robot = data;
			});
            // Autorobot select change
            Websocket.bind('autorobot/change-select', (data) => {
                let {cityids, ...cdata} = data;

				data.cityids.map(cityid => {
					let _city = self.findCity(+cityid);
					_city.autorobot = {
                        ...toJS(_city.autorobot),
                        ...cdata
                    };
				});
			});

			// Final Currency change
			Websocket.bind('finalcurr/change', (data) => {
				city.finalcurr.setValue(data.name, data.value);
			});
			Websocket.bind('finalcurr/update', (data) => {
				city.finalcurr = data;
			});

			// Обновление лимитов
			Websocket.bind('limits/change', (data) => {
				city.limits.setValue(data.type, data.value);
			});

			// Переключение лучших курсов
			Websocket.bind('currency/bestcurrency', (data) => {
				self.offices(data.city).map(item => {
					let _city = self.findCity(item.id);
					_city.best = toJS(data);
				})

				data.bestcurr && city.setValue("bestcurr", data.bestcurr);
			});

			Websocket.bind('balance/cashier', (data) => {
				city.balance = data;
			});

			Websocket.bind('board/update', (data) => {
				city.board = data;
			});

			Websocket.bind('cashbox/change', (data) => {
				Object.entries(data).map(([key, value]) => {
					city.cashbox.setValue(key, value);
				});
			});
		}

		return { fetchData, deleteCity, setLoading, wsUpdate }
	});

export const CitiesStore = Cities.create({});