import React from 'react';
import { observer } from "mobx-react-lite";

import {Box, Switch, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, Slider, Slide} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import { useStores } from '@store';

const useStyles = makeStyles((theme) => ({
	sliderCon: {
		width: '80%',
		margin: '0 auto',
	},
}));

export default () => {

	return(
		<>
		<ChatFontSize />
		<ChatNameFormat />
		</>
	);
}


const ChatFontSize = observer(() => {
	const classes = useStyles();
	const { chat } = useStores();

	const handleChange = (e, value) => {
		chat.setAppearance("fontsize", value);
	}

	return(
		<div className={classes.sliderCon}>
			<Typography gutterBottom>Размер шрифта</Typography>
			<Slider
			value={chat.appearance.fontsize}
			onChange={handleChange}
			step={1}
			min={10}
			max={14}
			//getAriaValueText={}
			valueLabelDisplay="off"
			marks={marks}
			/>
		</div>
	);
});

const ChatNameFormat = observer(() => {
	const classes = useStyles();
	const { chat } = useStores();

	const handleChange = (e) => {
		chat.setAppearance(e.target.name, e.target.checked);
	}

	return(
		<List dense>
			<ListItem>
				<ListItemText>Показывать фамилию</ListItemText>
				<ListItemSecondaryAction>
				<Switch
				checked={chat.appearance.nameformat}
				onChange={handleChange}
				name="nameformat"
				/>
				</ListItemSecondaryAction>
			</ListItem>
			<ListItem>
				<ListItemText>Временная метка</ListItemText>
				<ListItemSecondaryAction>
				<Switch
				checked={chat.appearance.dateformat}
				onChange={handleChange}
				name="dateformat"
				/>
				</ListItemSecondaryAction>
			</ListItem>
		</List>
	);
});

const marks = [
	{
		value: 10,
		label: <Box fontSize={10} fontWeight="bold">Aa</Box>,
	},
	{
		value: 11,
		label: <Box fontSize={11} fontWeight="bold">Aa</Box>,
	},
	{
		value: 12,
		label: <Box fontSize={12} fontWeight="bold">Aa</Box>,
	},
	{
		value: 13,
		label: <Box fontSize={13} fontWeight="bold">Aa</Box>,
	},
	{
		value: 14,
		label: <Box fontSize={14} fontWeight="bold">Aa</Box>,
	},
];