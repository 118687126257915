import { types, getParent, flow } from "mobx-state-tree";
import { Auth } from "../auth";

export const CashboxLimitsEntry = types.model("CashboxLimitsEntry", {
	cityid: types.number,
	general: types.number,
	boo: types.number,
	usd: types.number,
	eur: types.number,
	rub_collection: types.number,
	overlimit: types.number,
	min_collection: types.number,
	output: types.maybe(types.number),
	output_date: types.string,
	collection: types.maybe(types.number),
	collection_date: types.string,
	// Юзер отправил письмо в казначейство
	update: types.model({
		id: types.maybeNull(types.number),
		date: types.string,
	}),
	// Контролирующий юзер, отправил уведомление
	control: types.model({
		id: types.maybeNull(types.number),
		date: types.string,
	}),
	active: types.optional(types.boolean, false)
})
.views(self => ({
	get statusLimit() {
		if(self.rub_collection <= 0 || self.overlimit <= 0 || self.min_collection <= 0) return false;

		let val = getParent(self).balance.rub_balance - self.collection - self.output;
		return val >= self.rub_collection;
	},

	get statusWarningButton() {
		var now = new Date();
		var update = new Date(self.control.date);

		if(now.getTime() - 4500000 > update.getTime() ) {
			return false;
		} else if(!isNaN(update.getTime())) {
			return true;
		} else {
			return true;
		}
	},

	validDate(type) {
		return new Date(self[type]) instanceof Date && !isNaN(new Date(self[type]));
	}
}))
.actions(self => ({
	setValue: (type, value) => {
		self[type] = value;
	},

	setUserUpdate: (key) => {
		self[key].id = Auth.id;
		self[key].date = new Date().toString();
	},
}));