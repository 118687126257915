import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import {BrowserRouter as Router} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './hooks/snackbar';
import Snackbar from './hooks/snackbar';
// Header
import Main from 'app/layout/main';
import ErrorBoundary from './components/boundary';


import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import {blue, red, green, common} from '@mui/material/colors';
import { ruRU } from '@mui/x-data-grid';
import { ruRU as coreRuRU } from '@mui/material/locale';
// Fonts
import "@fontsource/roboto";
import '../assets/css/digital7.css';
import '../assets/css/custom.css';

const theme = createTheme({
	typography: {
		fontFamily: [
			'-apple-system','BlinkMacSystemFont','"Segoe UI"','Roboto',	'"Helvetica Neue"',	'Arial','"Noto Sans"','sans-serif',	'"Apple Color Emoji"','"Segoe UI Emoji"','"Segoe UI Symbol"','"Noto Color Emoji"',
		].join(','),
		button: {
			textTransform: 'none'
		}
	},
	palette: {
		primary: blue,
		secondary: red,
		success: {
			main: green[500],
			contrastText: common.white,
		}
	}},
	ruRU,
	coreRuRU
);

axios.defaults.baseURL = '/api';

axios.interceptors.response.use(undefined, error => {
	const {status, data, config} = error.response;

	switch(status) {
		case 500: Snackbar.error("На сервере произошла критическая ошибка. Код ошибки: " + status); break;
		case 600: Snackbar.error("База данных не смогла обработать запрос. Код ошибки: " + status); break;
		default: Snackbar.error("Сервер не смог обработать запрос. Код ошибки: " + status || "Неизвестен");
	}
	// eslint-disable-next-line no-console
	console.error("Axios critical status code: " + status,config);
});


const App = () => {
	return (
        <Router>
			<StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <HelmetProvider>
                        <ErrorBoundary>
                        <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}>
                            <SnackbarUtilsConfigurator />
                            <Main/>
                        </SnackbarProvider>
                        </ErrorBoundary>
                    </HelmetProvider>
                </ThemeProvider>
            </StyledEngineProvider>
		</Router>
    );
};

ReactDOM.render(<App />, document.getElementById('App'));