import {
	AccountBalanceWallet as BalancelistIcon, Assessment as AnalyticsIcon,
	Group as GroupIcon,
	Home as HomeIcon, Language as LanguageIcon, LocalAtm as CurrencyIcon,
	Speed as SpeedIcon
} from '@mui/icons-material';
import { useStores } from '@store';
import { DefaultLoader } from 'hooks/loader';
import { observer } from 'mobx-react-lite';
import MainPage from 'pages/main';
import { Error403, Error404 } from 'pages/__error';
import React, { lazy, Suspense, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Route, Switch, useLocation } from "react-router-dom";
import ErrorBoundary from '../../components/boundary';
import { SettingsRoute } from './settings';




export const routes = [
	{ path: '/',				Component: lazy(() => import('pages/main')),				name: 'Главная',		icon: <HomeIcon />, 		exact: true},
	{ path: '/balance/:cityid', Component: lazy(() => import('pages/balance/balance')), 	name: 'Баланс', 		access: "Cashier"},
	{ path: '/balance', 		Component: lazy(() => import('pages/balance/balance')), 	name: 'Баланс', 		icon: <BalancelistIcon />, 	access: "Cashier", 	visibility: "viewCashier"},
	{ path: '/geomap', 			Component: lazy(() => import('pages/geomap/geomap')), 	name: 'Карта РФ', 		icon: <LanguageIcon/>, 		exact: true,		access: "Controller" },
	{ path: '/currency', 		Component: lazy(() => import('pages/currency/')), 		name: 'Курсы валют', 	icon: <CurrencyIcon/>, 		exact: true,		access: "Controller"},
	{ path: '/balancelist', 	Component: lazy(() => import('pages/balancelist')),		name: 'Остатки валют', 	icon: <BalancelistIcon/>, 	exact: true, 		access: "Controller"},
	{ path: '/limits', 			Component: lazy(() => import('pages/limits')),			name: 'Лимиты', 		icon: <SpeedIcon/>, 		exact: true,		access: "Controller"},
	{ path: '/limits/cashbox', 			Component: lazy(() => import('pages/limits/cashbox')),	name: 'Лимиты инкассации', 		icon: <SpeedIcon/>, 		exact: true,		access: "Controller"},
	{ path: '/analytics', 		Component: lazy(() => import('pages/analytics/')), 		name: 'Аналитика', 		icon: <AnalyticsIcon/>, 	exact: true,		access: "Leader" },
	{ path: '/users', 			Component: lazy(() => import('pages/users/')), 			name: 'Пользователи', 	icon: <GroupIcon/>,			exact: true,		access: "Leader" },
	{ path: ['/users/edit/:id/:tab', '/users/edit/:id'], 	Component: lazy(() => import('pages/users/admin/')), 		name: 'Редактирование пользователей', access: "Leader" },
	{ path: '/settings', 		Component: lazy(() => import('pages/settings/')),			name: 'Настройки',		exact: true},
	{ path: '/city/:id', 		Component: lazy(() => import('pages/city/')),				name: 'Город', 			access: "Controller"},
	{ path: '/releases',		Component: lazy(() => import('pages/releases')), 			name: 'Вышедшие релизы', exact: true},
	{ path: '/parser',			Component: lazy(() => import('pages/parser/index')), 			name: 'Состояние парсера',exact: true, access: "Controller"},
	{ path: '/public/api', 		Component: lazy(() => import('pages/api/')), 				name: 'Публичные API', exact: true, access: "Controller"},
	{ path: '/logs', 		Component: lazy(() => import('pages/logs/')), 				name: 'Логи действий', access: "Controller"},
];


export const AppRouter = observer(() => {
	const { BreadCrumps } = useStores();

	const location = useLocation();
	let current = location.pathname.split('/');
	let background = location.state && location.state.background;

	useEffect(() => {
		// let route = routes.find(value => value.path.startsWith('/' + current[1]));
		// BreadCrumps.push({name: route?.name, page: current[1], path: location.pathname});
	}, [current[1]]);

	return(
		<Suspense fallback={<DefaultLoader/>}>
			<Switch location={background || location}>
			{routes.map(({ path, Component, name, access, exact }) => (
				<Route key={path} path={path} exact={exact}>
					<AuthRouter access={access}>
						<Helmet>
							<title>{name && name}</title>
						</Helmet>
						<ErrorBoundary>
							<Component />
						</ErrorBoundary>
					</AuthRouter>
				</Route>
			))}
			<Route exact path="/">
				<MainPage />
			</Route>
			<Route path="*">
				<Error404 />
			</Route>
			</Switch>
			{background && <Route exact path="/settings"><SettingsRoute /></Route>}
		</Suspense>
	);
});

const AuthRouter = observer(({children, access}) => {
	const { authStore } = useStores();

	return !access || authStore[access] ? children : <Error403 />;
});