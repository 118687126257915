import { LocationOn } from '@mui/icons-material';
import {
    Avatar, AvatarGroup, Box, Container, Grid, Link,
    List, ListItem, ListItemIcon, ListItemText, Paper, Skeleton, Stack, Typography
} from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { useStores } from '@store';
import UserAvatar from 'hooks/userAvatar';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';



const useStyles = makeStyles({
	root:{
		'& .MuiAvatar-root': {
			marginLeft: 0
		},
	}
});

export default observer(() => {
	const {users, authStore, quotes} = useStores();
	const user = users.findUser(authStore.id);

	return(
		<Container maxWidth="md">
            <Grid container spacing={3}>
                <Grid item>
                    <Typography variant="h4" gutterBottom>Добро пожаловать {user?.onlyName} 👋</Typography>
                    <Typography>👈 Воспользуйтесь левым меню, чтобы перейти на нужную страницу.</Typography>
                    <Box mt={5}>
                        <CityInfo city={user?.city}/>
                        {user && <OnlineInCity city={user.city}/>}
                    </Box>
                </Grid>
                <Grid item>
                    <Stack spacing={2}>
                        {quotes.data?.map(item => <QuotesItem {...item} key={item.id}/>)}
                    </Stack>
                </Grid>
            </Grid>
		</Container>
	);
});

const QuotesItem = observer(({type, price, val2, val3}) => {
    return(
        <Paper sx={{p: 1}}>
            <Box sx={{display: 'flex', alignItems: 'flex-end', fontSize: '0.7rem', color: 'GrayText'}}>
                <Typography color="primary" fontSize="1.3rem" fontWeight={500}>{type.toUpperCase()}</Typography>
                /RUB
            </Box>
            <Box sx={{display: 'flex'}}>
                <Typography sx={{fontSize: '1.4rem', fontWeight: 600}}>{price}</Typography>

                <Box sx={{fontSize: '0.7rem', pl: 1, color: val2 > 0 ? 'success.main' : 'error.main'}}>
                    <div>{val2}</div>
                    <div>({val3})</div>
                </Box>
            </Box>
        </Paper>
    );
});

const CityInfo = observer(({city}) => {
	const {cities} = useStores();

	useEffect(() => {
		cities.fetchData();
	}, []);

	return(
		<Box>
			<Typography variant="h5" gutterBottom>Ваш город {city}</Typography>
			<Typography>Все офисы в вашем городе:</Typography>
			{cities.isLoading && <div><Skeleton variant="text" height={50}/><Skeleton variant="text" height={50}/></div>}
			{!cities.isLoading && <List dense={true}>
				{cities?.offices(city).map(item =>
					<UserCityOfficies item={item} key={item.id}/>
				)}
			</List>}
		</Box>
	);
});

const UserCityOfficies = observer(({item}) => {
	const {auth} = useStores();

	return(
		<ListItem>
			<ListItemIcon>
				{auth.cityid == item.id ? <LocationOn style={{ color: green[500] }}/> : <LocationOn />}
			</ListItemIcon>
			<ListItemText
				primary={!auth.viewCashier ? <Link to={`/city/${item.city}`} component={RouterLink}>{item.city}</Link> : <Link>{item.city}</Link>}
				secondary={item.address}
			/>
		</ListItem>
	);
});

const OnlineInCity = observer(({city}) => {
	const classes = useStyles();
	const {users} = useStores();
	const userlist = users.findOnlineInCity(city);
	return(
		<>
		<Typography variant="h5" gutterBottom>Ваши коллеги по городу</Typography>
		<Box display="flex" justifyContent="left">
			<AvatarGroup max={10} className={classes.root}>
				{userlist ? userlist.map(item => <UserAvatar userid={item.id} key={item.id} size="big"/>) : <Skeleton><Avatar /></Skeleton>}
			</AvatarGroup>
		</Box>
		</>
	);
});