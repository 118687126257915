import axios from "axios";
import { observable } from "mobx";
import { Websocket } from './websocket';

export var Auth = observable({
	auth: [],
	id: null,
	city: null,
	cityid: null,
	hidecity: [],
	hide_type: false,
	isAuth: false,
	isLoading: true,

	getAPI() {
		axios.get("/api/auth").then(res => {
			if(res.data != null) {
				this.isAuth = true;

				this.id = res.data.id;
				this.auth = res.data;
				this.city = res.data.city;
				this.cityid = res.data.cityid;
				this.hidecity = res.data.hide_city;
				this.hide_type = res.data.hide_type;

				// store.set('session', {
				// 	id: res.data.id,
				// 	city: res.data.city,
				// 	cityid: res.data.cityid,
				// })
			}
			this.isLoading = false;
		});
	},

	checkAuth() {
		if(this.isAuth) {
			axios.get("/auth").then(res => {
				if(res.data == null) {
					this.isLoading = true;
					this.isAuth = false;
					this.auth = [];
					this.id = null;
					this.hidecity = [];
					window.location.replace("/login");
				}
			});
		}
	},

	wsUpdate() {
		Websocket.bind('user/hidecity', (data) => {
			this.hidecity = data.hide;
		});
		Websocket.bind('user/edit/value', (data) => {
			if(data.userid == this.id) {
				this[data.name] = data.value;
			}
		});
		Websocket.bind('user/settings', (data) => {
			Object.keys(data).forEach((key) => {
				this.auth[key] = data[key];
			});
		});
		Websocket.bind('user/addavatar', (data) => {
			this.changeAvatar(data.avatar);
		});
	},

	setCityid: function(cityid) {
		this.cityid = cityid;
	},

	changeAvatar: function(avatar) {
		this.auth.avatar = avatar;
	},

	get Name() {
		let name = this.auth.fullname?.split(" ");

		return name ? name[1] : "";
	},

	get Developer() {
		return this.auth.role_id == 1 ? true : false;
	},

	get Leader() {
		return this.auth.role_id == 1 || this.auth.role_id == 512 ? true : false;
	},

	get Controller() {
		return this.auth.role_id == 1 ||
		this.auth.role_id == 512 ||
		this.auth.role_id == 65536 ? true : false;
	},
	get Cashier() {
		return this.auth.role_id == 1 ||
		this.auth.role_id == 512 ||
		this.auth.role_id == 65536 ||
		this.auth.role_id == 2097152 ? true : false;
	},
	get viewCashier() {
		return this.auth.role_id == 2097152 ? true : false;
	}
});

Auth.getAPI();

setInterval(() => {
	Auth.checkAuth();
}, 1800 * 1000);