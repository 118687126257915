import React from "react";
import {observer} from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { ToggleButton } from "@mui/material";

import {useStores} from '@store';

const StyledToggleButton = styled(ToggleButton)(({ trans, theme }) => ({
	border: 0,
	minWidth: 48,
	height: 48,
	borderRadius: 0,
	color: 'rgba(0, 0, 0, 0.38)',
	...(trans && {
			color: '#fff',
	}),
}));

export const AppBarButton = observer((props) => {
	const {settings} = useStores();

	return(
		<StyledToggleButton {...props} trans={settings.headerTransp ? 1 : 0} value={0}>
			{props.children}
		</StyledToggleButton>
	);
});