import PropTypes from "prop-types";
import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import axios from "axios";
import {observer} from 'mobx-react-lite';
import {Box, Button, Badge, Card, CardContent, CardMedia, Fade, Skeleton, Paper, Grow, Zoom, Collapse, ClickAwayListener, MenuList, MenuItem, Typography, Divider, Avatar, Tooltip, List, ListItem, ListItemText, ListItemIcon} from '@mui/material';
import { LocationCity, Person, VerifiedUser, Mail } from '@mui/icons-material';
import { makeStyles, withStyles } from '@mui/styles';
import { motion } from "framer-motion";
import { ComponentLoader } from './loader';
import { useStores } from '@store';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		width: 400,
		height: 200,
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		flex: '1 0 auto',
		padding: 10,
	},
	cover: {
		width: 250,
	},
	small: {
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),
	},

	default: {
		width: theme.spacing(4),
		height: theme.spacing(4),
	},

	medium: {
		width: theme.spacing(5),
		height: theme.spacing(5),
	},

	big: {
		width: theme.spacing(6),
		height: theme.spacing(6),
	}
}));

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
		padding: 0,
		maxWidth: 400,
	},
}))(Tooltip);

const StyledBadge = withStyles((theme) => ({
	badge: {
	backgroundColor: '#44b700',
	color: '#44b700',
	boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
	'&::after': {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		borderRadius: '50%',
		//animation: '$ripple 1.2s infinite ease-in-out',
		border: '1px solid currentColor',
		content: '""',
	},
	},
}))(Badge);

const UserAvatar = observer(({userid, size}) => {
	const classes = useStyles();
	const { users } = useStores();
	const user = users.findUser(userid);

	const [open, setOpen] = useState(false);
	const ttClose = () => setOpen(false);
	const ttOpen = () => setOpen(true);

	if(users.isLoading) {
		return(
			<Skeleton animation="wave" variant="circular" className={size ? classes[size] : classes.small} />
		);
	}
	return(
		<LightTooltip
		title={<TooltipContent userid={userid}/>}
		onClose={ttClose}
		open={open}
		arrow
		placement="right-start"
		TransitionComponent={Fade}
		>
			<div>
				<motion.div
				variants={{
					rest: { scale: 1 },
					hover: { scale: 1.3 },
					pressed: { scale: 0.95 }
				}}
				initial="rest"
				whileHover="hover"
				whileTap="pressed"
				transformTemplate={(props, transform) =>
					// Disable GPU acceleration to prevent blurry
					transform.replace(" translateZ(0)", "")
				}
				>
					<StyledBadge
					overlap="circular"
					anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
					}}
					variant="dot"
					invisible={!user?.status}
					className={size ? classes[size] : classes.small}
					>
						<Avatar
						src={userid === 0 ? user?.avatar : user?.avatar ? `${process.env.IMG}/assets/img/avatars/${user?.avatar.split('.').join('_thumb.')}` : null}
						onClick={ttOpen}
						className={size ? classes[size] : classes.small}
						/>
					</StyledBadge>
				</motion.div>
			</div>
		</LightTooltip>
	);
});

const TooltipContent = ({userid}) => {
	const classes = useStyles();
	const [user, setUser] = useState(null);

	useEffect(() => {
		axios.get('/user/data/'+ userid).then(res => setUser(res.data));
	}, []);

	return(
	<Card className={classes.root}>
	{!user ? <ComponentLoader /> :
	<>
	<div className={classes.details}>
		<CardContent className={classes.content}>
		<List dense disablePadding>
			<ListItem disableGutters>
				<ListItemIcon><Person /></ListItemIcon>
				<ListItemText primary={user.fullname} />
			</ListItem>
			<ListItem disableGutters>
				<ListItemIcon><Mail /></ListItemIcon>
				<ListItemText primary={user.email} />
			</ListItem>
			{/* <ListItem disableGutters>
				<ListItemIcon><VerifiedUser /></ListItemIcon>
				<ListItemText primary={user.role} />
			</ListItem> */}
			<ListItem disableGutters>
				<ListItemIcon><LocationCity /></ListItemIcon>
				<ListItemText primary={user.city} secondary={user.region} />
			</ListItem>
		</List>
		</CardContent>
	</div>
	<CardMedia
		className={classes.cover}
		image={user.avatar ? `${process.env.IMG}/assets/img/avatars/${user.avatar}` : null}
		title="Live from space album cover"
	/>
	</>
	}
	</Card>

	);
};

TooltipContent.propTypes = {
    userid: PropTypes.any
}

UserAvatar.propTypes = {
	avatar: PropTypes.any,
	size: PropTypes.any,
	userid: PropTypes.any,
	status: PropTypes.number,
}

export default UserAvatar;