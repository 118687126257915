import { Autocomplete, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { axios, cancelAxios } from "api";
import PropTypes from "prop-types";
import React, { FunctionComponent, useEffect, useState } from 'react';

export const RussiaCities: FunctionComponent<typeof Autocomplete> = props => {
	const {value, ...autoComp} = props;
	const [cities, setCities] = useState([]);
	const [open, setOpen] = useState(false);
	const [load, setLoad] = useState(null);

	useEffect(() => {
		let mounted = true;

		if(cities.length === 0) {
			if(value !== undefined || open) {
				setLoad(true);
				axios.get('/cities/russia').then(res => {
					if(mounted) {
						setCities(res.data);
						setLoad(false);
					}
				});
			}
		}
		return () => {cancelAxios};
	}, [open]);

	return(
	<Autocomplete
		value={cities.find(item => item.city == value) || null}
		options={cities.sort((a, b) => a.region.localeCompare(b.region))}
		disableClearable={true}
		loading={load}
		open={open}
		onOpen={() => setOpen(true)}
		onClose={() => setOpen(false)}
		groupBy={option => option.region}
		getOptionLabel={option => option.city}
		renderOption={(props2, option, { selected }) => (
			<li {...props2}>
			<Grid container>
				<Grid item xs={6}>{option.city}</Grid>
				<Grid item xs={6}>
					<Typography variant="caption">{option.region}</Typography>
				</Grid>
			</Grid>
			</li>
		)}
		{...autoComp}
		renderInput={params => (
			<TextField
			{...params}
			inputProps={{
				...params.inputProps,
				autoComplete: "off",
			}}
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<>
					{load ? <CircularProgress size="1.5rem"/> : null}
					{params.InputProps.endAdornment}
					</>
				),
			}}
			label="Город"
			variant="outlined"
			/>
		)}
	/>);
}
RussiaCities.propTypes = {
	value: PropTypes.string
}
