import React, {useState, useEffect} from 'react';
import { Link as RouterLink } from "react-router-dom";
import axios from 'axios';

import { observer } from "mobx-react-lite";
import { List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, Box, ClickAwayListener, ListItemAvatar, Avatar, Link, Divider, Skeleton } from '@mui/material';
import { Inbox } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { useStores } from '@store';
import { ComponentLoader } from 'hooks/loader';
import { ReleaseTemplate, ReplyMsgTemplate, ParserTemplate } from './templates';

const useStyles = makeStyles(theme => ({
	noData: {
		display: "flex",
		textAlign: "center",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		flexDirection: "column",
		paddingRight: "40px",
		paddingLeft: "40px",
	},
}));

export const NotifyContent = () => {
	const classes = useStyles();
	const [notify, setNotify] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		axios.get('/notify/').then(res => {
			setNotify(res.data);
			setLoading(false);
		});
	}, []);

	if(loading) return <Box height={200}><ComponentLoader /></Box>;
	return(
		<List>
			{notify ? notify.map(item => (
				<NotifyTemplate row={item} key={item.id}/>
			)) :
				<Box className={classes.noData}>
					<Box><Inbox style={{ fontSize: 40 }} color="action"/></Box>
					<Box fontWeight={500} color="text.secondary" mb={3}>В данный момент у вас нет уведомлений.</Box>
				</Box>
			}
		</List>
	);
}

const NotifyTemplate = ({row}) => {
	const RenderTemplate = () => {
		switch(row.event) {
			case "release": {
				return <ReleaseTemplate {...row}/>
			}
			case "reply": {
				return <ReplyMsgTemplate {...row}/>
			}
			case "kovalut":
			case "excur":
			case "bankiros":
			case "custom": {
				return <ParserTemplate {...row}/>
			}
		}
	}

	return(
		<ListItem dense divider alignItems="flex-start">
			{RenderTemplate()}
		</ListItem>
	);
};