import { types, getParent } from "mobx-state-tree";

export const RobotEntry = types.model({
	cityid: types.number,
	bUSD: types.maybe(types.number),
	sUSD: types.maybe(types.number),
	bEUR: types.maybe(types.number),
	sEUR: types.maybe(types.number),
	bCNY: types.maybe(types.number),
	sCNY: types.maybe(types.number),
	mail: types.maybe(types.number),
	delay: types.maybe(types.number),
	// update: types.model({
	// 	id: types.maybeNull(types.number),
	// 	date: types.string,
	// }),
})
.views(self => ({
	get fullrobot() {
		let city = getParent(self);

		if(self.bUSD && self.sUSD && self.bEUR && self.sEUR) {
			if(city.cny) {
				return self.bCNY && self.sCNY ? true : false;
			}

			return true;
		} else return false;
	},
}))
.actions(self => ({
	setValue: (type, value) => {
		self[type] = value;
	},
}));