import React from 'react';
import {Grid, TextField} from '@mui/material';

export default ({FIO, setFIO}) => {
	return(
		<>
		<Grid item xs={4}>
			<TextField
			required
			label="Фамилия"
			variant="outlined"
			type="text"
			value={FIO[0]}
			onChange={(e) => setFIO({...FIO, 0: e.target.value})}
			fullWidth/>
		</Grid>
		<Grid item xs={4}>
			<TextField
			required
			label="Имя"
			variant="outlined"
			type="text"
			value={FIO[1]}
			onChange={(e) => setFIO({...FIO, 1: e.target.value})}
			fullWidth/>
		</Grid>
		<Grid item xs={4}>
			<TextField
			required
			label="Отчество"
			variant="outlined"
			type="text"
			value={FIO[2]}
			onChange={(e) => setFIO({...FIO, 2: e.target.value})}
			fullWidth/>
		</Grid>
		</>
	);
}