import React, {useState} from "react";

import { Menu, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import { AppBarButton } from './style/iconbutton';

import {AuthAccess} from 'hooks/access';
import AddCity from './adds/addcity';
import AddUser from './adds/adduser';
import AddParser from './adds/addparser';
import AddRelease from './adds/addrelease';
import { AddMailReceiver } from "./adds/mail-receiver";
import { AddWhiteIP } from "./adds/white-ip";

export default () => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return(
		<AuthAccess access="Leader">
			<AppBarButton selected={Boolean(anchorEl)} onClick={handleClick}>
				<Add />
			</AppBarButton>
			<Menu
			keepMounted // fix textfield focus
			onClick={handleClose} // fix textfield focus
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			>
				<AddCity />
				<AddUser />
				<AddMailReceiver />
				<AddParser />
				<AddWhiteIP />
				<AuthAccess access="Developer">
					<Divider />
					<AddRelease />
				</AuthAccess>
			</Menu>
		</AuthAccess>
	);
}