import { Backdrop, Box, CircularProgress, CssBaseline, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useStores } from '@store';
import { observer } from "mobx-react-lite";
import LoginPage from 'pages/authentication/login';
import ResetPassword from 'pages/authentication/resetpass';
import React, { useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Redirect, Route, useLocation } from "react-router-dom";
import { AppRouter } from "../router/router";
import DrawerMenu from './drawer';





const useStyles = makeStyles(theme => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(2),
		backgroundColor: "#f4f6f8",
		minHeight: "calc(100vh)",
	},
}));

export default () => {
	return(
		<ProtectAuth>
			<Box display="flex">
				<CssBaseline />
				<DrawerMenu/>
				<RouterContent/>
			</Box>
		</ProtectAuth>
	);
}

const ProtectAuth = observer(({ children }) => {
	const { auth } = useStores();
	let location = useLocation();
	if(auth.isLoading) return <Backdrop open={true}><CircularProgress color="inherit" /></Backdrop>;

	if(!auth.isAuth && (location.pathname == "/login" || location.pathname == "/recoverypass")) {
		return(
			<>
			<Route exact path="/login">
				<Helmet>
					<title>Вход в систему</title>
				</Helmet>
				<LoginPage />
			</Route>
			<Route exact path="/recoverypass">
				<Helmet>
					<title>Сброс пароля</title>
				</Helmet>
				<ResetPassword />
			</Route>
			</>
		);
	}

	return <Route render={({ location }) => auth.isAuth ? (children) : (<Redirect to={{pathname: "/login", state: { from: location }}}/>)}/>;
});

const RouterContent = () => {
	const classes = useStyles();
	const { ws, notify, users, cities, quotes } = useStores();

	useEffect(() => {
		ws.InitialSocket();
		notify.fetchData();
		users.fetchData();
		cities.fetchData();
        quotes.fetchData();
	}, []);

	return (
		<main className={classes.content}>
			<Toolbar/>
			<AppRouter/>
		</main>
	);
}
