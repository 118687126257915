import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import {Box, Button, IconButton, Tooltip, Typography} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import TimeAgo from '../../hooks/timeago';
import { Reply, Close } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/user-store';
import UserAvatar from '../../hooks/userAvatar';

import Emoji from 'react-emoji-render';

const useStyles = makeStyles(theme => ({
	header: {
		padding: "4px",
		fontWeight: 700,
		fontSize: 11,
	},
	comment: {
		position: "relative",
		maxWidth: "100%",
		fontSize: 11,
		backgroundColor: "#f8f9fa",
		border: "1px solid rgba(0,0,0,.125)",
		borderRadius: '0 8px 8px 8px',
		marginLeft: theme.spacing(0.5),
		cursor: "pointer",
	},
	myComm: {
		backgroundColor: "#3678ad",
		color: "#fff",
		borderRadius: '8px 8px 0 8px',
		marginLeft: 'inherit',
		marginRight: theme.spacing(0.5),
	},
	megatext: {
		'& span': {
			fontSize: '1.3em',
		}
	},
	avatar: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	selected: {
		background: "rgb(33 150 243 / 0.2)",
	},
	reply: {
		margin: 4,
		paddingLeft: 8,
		borderLeft: "2px solid",
		borderColor: theme.palette.primary.light,
		background: "rgb(0 0 0 / 10%)",
		'&:hover': {
			borderColor: theme.palette.primary.main,
		}
	},
	replyHead: {
		fontSize: 10,
		fontWeight: "bold",
	},
	replyText: {
		fontSize: 10,
	},
}));

const ActionButton = withStyles({
	root: {
		position: "absolute",
		right: 0,
		minWidth: 15,
		padding: "1px 1px 1px 10px",
	},
})(Button);

export default observer(({data, message}) => {
	const classes = useStyles();

	const { chat, users, auth } = useStores();
	const [hover, setHover] = useState(false);

	const user = users.findUser(data.user);

	let date = new Date(data.rr_time);
		date = date.toTimeString().split(' ')[0].split(':');
		date = `${date[0]}:${date[1]}`;

	let viewUserName = chat.appearance.nameformat ? user?.userName : user?.onlyName;

	let my = data.user == auth.id ? true : false;
	let replyMessage = message.find(item => item.id === data.reply || item.id === data.reply?.id);

	const handleClick = (e) => {
		chat.selectMsg = chat.selectMsg?.id == data.id ? null : data;
	}

	const handleReply = (e) => {
		e.stopPropagation();
		chat.reply = data;
	}

	return(
		<>
			<Box
			display="flex"
			justifyContent={my ? "flex-end" : "flex-start"}
			alignItems={my ? "flex-end" : "flex-start"}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			p={0.25}
			className={chat.selectMsg?.id == data.id ? classes.selected : null}
			>
				<Box>
					{!my && <UserAvatar userid={data.user} />}
				</Box>
				<Box>
				{!my &&
					<Box className={classes.header} sx={{fontSize: chat.appearance.fontsize}}>
						<Box display="inline" component="small" mr={1} color="primary.main">{viewUserName}</Box>
						<Box display="inline">
							{chat.appearance.dateformat ? <Box component="small" fontWeight="bold" color="text.disabled">{date}</Box> : <TimeAgo date={data.rr_time}/>}
						</Box>
					</Box>
				}
				<Box
				className={my ? `${classes.comment} ${classes.myComm}` : classes.comment}
				onClick={handleClick}
				sx={{fontSize: chat.appearance.fontsize}}
				>
					{hover && !my && <Tooltip disableInteractive title="Ответить"><ActionButton startIcon={<Reply />} variant="contained" onClick={handleReply}><></></ActionButton></Tooltip>}
					<Box p={0.5}>
						{replyMessage && <ReplyPattern data={replyMessage} my={my} />}
						<Emoji
						text={data.message}
						className={classes.megatext}
						/>
					</Box>
				</Box>
				</Box>
				<Box>
				{my && <UserAvatar userid={data.user} />}
				</Box>
			</Box>
		</>
	);
});

export const ReplyPattern = observer(({data, action = false, my = false}) => {
	const classes = useStyles();
	const { chat, users } = useStores();
	const user = users.findUser(data.user);
	const RemoveReply = () => {chat.reply = null;}

	return(
		<Box display="flex" className={classes.reply}>
			<Box flexGrow={1} /*style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}*/>
				<Typography variant="subtitle1" color={my ? "inherit" : "primary"} className={classes.replyHead}>{user?.userName}</Typography>
					<Emoji
						text={data.message}
						className={`${classes.megatext} ${classes.replyText}`}
					/>
			</Box>
			<div>
				{action && <IconButton onClick={RemoveReply} size="small"><Close /></IconButton>}
			</div>
		</Box>
	);
});