import React, {useState, useEffect, useRef} from 'react';
import { Link as RouterLink, useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { observer } from "mobx-react-lite";

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, RadioGroup, FormControlLabel, Radio, Box} from '@mui/material';
import { Menu as MenuIcon, ArrowDropDown, ExitToApp, Settings as SettingsIcon, LocationOn, CellWifi, EventNote, GitHub, Notifications } from '@mui/icons-material';
import {red, green} from '@mui/material/colors';
import {LoadingButton} from '@mui/lab';

import { useStores } from '@store';
import { ButtonLoader } from 'hooks/loader';
import { AppBarButton } from './style/iconbutton';

const ChangeOffice = observer(() => {
	const { auth, cities } = useStores();
	const city = cities.findCity(auth.cityid);

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return(
		auth.auth.role_id == 2097152 &&
		<>
		<AppBarButton onClick={handleOpen}>
			{cities.isLoading ? <ButtonLoader /> :
			<div style={{ maxWidth: 120, whiteSpace: 'nowrap' }}>
				<Box component="div" sx={{ display: 'flex', textOverflow: 'ellipsis', overflow: 'hidden', }}>
					<LocationOn style={{color: city ? green[500] : red[500]}}/>
					{city ? city.address : <Box sx={{color: 'error.main'}}>Не выбран</Box>}
				</Box>
			</div>
			}
		</AppBarButton>
		<DialogChangeOffice
		keepMounted
		open={open}
		onClose={handleClose}
		value={auth.cityid}
		city={city}
		/>
		</>
	);
});

const DialogChangeOffice = observer((props) => {
	const { onClose, value: valueProp, open, city, ...other } = props;

	const { auth, cities } = useStores();
	const offices = cities.offices(auth.city);

	const [value, setValue] = useState(valueProp);
	const [load, setLoad] = useState(false);
	const radioGroupRef = useRef(null);

	useEffect(() => {
		if (!open) {
			setValue(valueProp);
		}
	}, [valueProp, open]);

	const handleEntering = () => {
		if (radioGroupRef.current != null) {
			radioGroupRef.current.focus();
		}
	};

	const handleCancel = () => onClose();

	const handleOk = async () => {
		setLoad(true);

		const res = await axios.post('/settings/office/change', {cityid: value});
		if(res?.data.success) {
			auth.setCityid(value);
		}

		onClose();
		setLoad(false);
	};

	const handleChange = (event) => {
		let val = event.target.value;
		setValue(val ? +val : null);
	};

	return(
		<Dialog
		maxWidth="sm"
		TransitionProps={{ onEntering: handleEntering }}
		aria-labelledby="confirmation-dialog-title"
		open={open}
		{...other}
		>
		<DialogTitle>Смена офиса</DialogTitle>
		<DialogContent dividers>
			<Box sx={{minWidth: 400}}>
				<RadioGroup
				ref={radioGroupRef}
				aria-label="ringtone"
				name="ringtone"
				value={value}
				onChange={handleChange}
				>
				{offices.map((item) => (
					<FormControlLabel
					value={item.id}
					key={item.id}
					control={<Radio />}
					label={<Box display="flex"><Box sx={{fontWeight: 600}}>{item.city}</Box> <Box sx={{color: 'text.secondary', pl: 3}}>{item.address}</Box></Box>}
					/>
				))}
					<FormControlLabel
					value={null}
					control={<Radio />}
					label={<Box sx={{fontWeight: 600}}>Не выбрано</Box>}
					/>
				</RadioGroup>
			</Box>
		</DialogContent>
		<DialogActions>
			<Button autoFocus onClick={handleCancel}>Cancel</Button>
			<LoadingButton loading={load} onClick={handleOk}>Ok</LoadingButton>
		</DialogActions>
		</Dialog>
	);
});

export default ChangeOffice;