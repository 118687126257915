import React, { useState } from 'react';
import { observer } from "mobx-react-lite";
import axios from "axios";

import {Box, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Delete, Reply } from '@mui/icons-material';

import { useStores } from '@store';
import { AuthAccess } from 'hooks/access';
import ChatSettings from './settings/index';
//import UserBlock from './userblock';

const useStyles = makeStyles(theme => ({
	bg: {
		//borderBottom: '1px solid rgba(0,0,0,.125)',
		backgroundColor: theme.palette.primary.main,
		color: '#fff',
	},
}));

export default observer(() => {
	const classes = useStyles();
	const { chat } = useStores();

	return (
		<div className="draggable-title" style={{cursor: 'move'}}>
		<Box display="flex" className={classes.bg} p={0.5}>
			{chat.selectMsg ?
			<>
			<Box flexGrow={1} p={0.5}><Typography color="inherit" variant="subtitle2">Выбрано сообщение</Typography></Box>
			<Box>
				<ReplyMsg />
				<DeleteMsg />
			</Box>
			</>
			:
			<>
			<Box flexGrow={1} p={0.5}><Typography color="inherit" variant="subtitle2">Общий канал</Typography></Box>
			<Box>
				{/*<UserBlock />*/}
				<ChatSettings />
			</Box>
			</>
			}
		</Box>
		</div>
	);
});

const ReplyMsg = observer(() => {
	const { chat } = useStores();

	const handleClick = () => {
		chat.reply = chat.selectMsg;
	}

	return(
		<Tooltip disableInteractive title="Ответить"><IconButton color="inherit" size="small" onClick={handleClick}><Reply /></IconButton></Tooltip>
	);
});

const DeleteMsg = observer(() => {
	const { chat, ws } = useStores();

	const handleDelete = () => {
		var deleteId = chat.selectMsg.id;
		axios.delete(`/chat/message/delete/${deleteId}`).then(res => {
			res?.data.success && ws.send('chat/delete', {id: deleteId});
		});

		chat.resetSelect();
	}

	return(
		<AuthAccess access="Leader">
			<Tooltip disableInteractive title="Удалить"><IconButton color="inherit" size="small" onClick={handleDelete}><Delete /></IconButton></Tooltip>
		</AuthAccess>
	);
});