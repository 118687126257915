import React, { useState } from 'react';
import { observer } from "mobx-react-lite";

import {Box, IconButton, Popover, Tooltip, Typography, Button, List, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction, Slider, Slide} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { DynamicFeed, Settings, Close, NavigateNext, NavigateBefore } from '@mui/icons-material';

import { useStores } from '@store';
import ChatAppearance from './appearance';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(1),
		width: 300,
	},
	closeButton: {
		padding: theme.spacing(0.5),
		minWidth: 32,
	},
	backButton: {
		minWidth: 32,
	},
}));

export default () => {
	const classes = useStyles();
	const [container, setContainer] = useState(null);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	}

	const handleClose = () => {
		setAnchorEl(null);
		setContainer(null);
	}

	return(
		<>
		<Tooltip disableInteractive title="Настройка чата"><IconButton color="inherit" size="small" onClick={handleClick}><Settings /></IconButton></Tooltip>
		<Popover
		open={open}
		anchorEl={anchorEl}
		onClose={handleClose}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		transitionDuration={0}
		>
			<Box className={classes.container}>
				<Typography component="div">
					<Box display="flex">
						<Box className={classes.backButton}>
							{container > 0 && <Button className={classes.closeButton} onClick={() => setContainer(0)}><NavigateBefore /></Button>}
						</Box>
						<Box flexGrow={1} justifyContent="center" textAlign="center" fontWeight={500}>Настройки чата</Box>
						<Box>
							<Button className={classes.closeButton} onClick={handleClose}><Close fontSize="small"/></Button>
						</Box>
					</Box>
				</Typography>

				{!container && <Slide in timeout={container === null ? 0 : 300} direction="left"><div><MainContainer container={container} setContainer={setContainer}/></div></Slide>}
				{container === 1 && <Slide in direction="right"><div><ChatAppearance /></div></Slide>}
			</Box>
		</Popover>
		</>
	);
}

const MainContainer = observer(({container, setContainer}) => {
	const { chat } = useStores();

	const handleDraggable = () => {
		chat.setDrag();
	}

	return(
		<List
		dense
		subheader={
			<ListSubheader>
				Мои настройки
			</ListSubheader>
		}
		>
			<ListItem button onClick={() => setContainer(1)}>
				<ListItemText>Внешний вид чата</ListItemText>
				<NavigateNext />
			</ListItem>
			<ListItem button onClick={handleDraggable} disabled>
				<ListItemText>Чат в отдельном окне</ListItemText>
				<DynamicFeed />
			</ListItem>
		</List>
	);
});
