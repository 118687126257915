import { types, getParent, flow } from "mobx-state-tree";

export const LimitsEntry = types.model("LimitsEntry", {
	cityid: types.number,
	adds: types.number,
	limited: types.number,
	limits: types.number,
	pickup: types.number,
})
.views(self => ({
	get residue() {
		let balance = getParent(self).balance;
		let nowExpense = balance.usd_expense + balance.eur_expense + balance.cny_expense;
		let count = self.limits - self.limited - self.pickup + self.adds - nowExpense;

		return count;
	},

	get residuePercent() {
		let start = self.residue;
		let MAX = self.limits;

		let math = Math.floor((start - 0) * 100 / (MAX - 0));

		return math > 100 ? 100 : math < 0 ? 0 : math; // проценты не выйдет за рамки от 0 до 100
	},

	get spent() {
		return self.limits - self.limited;
	},
}))
.actions(self => ({
	setValue: (type, value) => {
		self[type] = value;
	},
}));