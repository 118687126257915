import { Delete, SettingsInputComposite } from '@mui/icons-material';
import { CircularProgress, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader, MenuItem, TextField } from '@mui/material';
import axios from "axios";
import { ComponentEmpty } from 'components/empty';
import Number from 'imask/esm/masked/number';
import React, { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { DialogTemplate } from './components/dialog';

export const AddWhiteIP = () => {
	const [data, setData] = useState(null);
	const [subload, setSubLoad] = useState(false);

	const handleClose = (e) => {
		setData(null);
	}

	const handleSubmit = async (e) => {
		setSubLoad(true);
		const res = await axios.post('/admin/whiteip/add', {
			ip: data
		});

		if(res.data) {
			setSubLoad(false);
			setData(null);
		}
	}

	return(
		<DialogTemplate
		type="add"
		render={
		<MenuItem>
			<ListItemIcon><SettingsInputComposite /></ListItemIcon>
			Добавить IP в исключение
		</MenuItem>
		}
		menuText="Добавить IP в исключение"
		contentText="Исключает IP адресс из проверок безопасности. Позволяет пользователю отправлять неограниченное кол-во запросов и не быть заблокированым."
		onClose={handleClose}
		onSubmit={handleSubmit}
		noSubmitClose
		disabled={!data}
		>
			<TextField
			fullWidth
			label="IP address"
			placeholder="Введите IP адресс, чтоб исключить его из проверок"
			value={data || ''}
			onChange={(e) => setData(e.target.value)}
			InputProps={{
				inputComponent: IpMaskInput,
			}}
			/>
			<WhiteIpList subload={subload}/>
		</DialogTemplate>
	);
}

const WhiteIpList = ({subload}) => {
	const [data, setData] = useState(null);

	useEffect(() => {
		axios.get('/admin/whiteip/get').then(res => setData(res.data));
	}, [subload]);

	return(
		<List
		sx={{maxWidth: 300}}
		subheader={<ListSubheader>Список разрешенных IP</ListSubheader>}
		disablePadding
		>
			<ComponentEmpty data={data}>
			{data?.map((item, index) =>
				<WhiteIpItem
				item={item}
				index={index}
				key={item.id}
				data={data}
				setData={setData}
				/>
			)}
		</ComponentEmpty>
		</List>
	);
}

const WhiteIpItem = ({item, index, ...props}) => {
	const [load, setLoad] = useState(false);

	const handleDelete = async () => {
		setLoad(true);

		const res = await axios.delete(`/admin/whiteip/delete/${item.id}`);
		res.data.success && props.setData(props.data.filter(ttt => ttt.id != item.id));

		setLoad(false);
	}

	return(
		<ListItem disablePadding>
			<ListItemText primary={`${index + 1}. ${item.ip}`}/>
			<IconButton
			color="error"
			onClick={handleDelete}
			>
				{load ? <CircularProgress size="1em"/> : <Delete />}
			</IconButton>
		</ListItem>
	);
}

const IpMaskInput = React.forwardRef(function TextMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
		{...other}
		inputMode="numeric"
		autoComplete="off"
		mask="IP.IP.IP.IP"
		blocks={{
			IP: {
				mask: Number,
				scale: 0,
				min: 0,
				max: 255
			}
		}}
		inputRef={ref}
		onAccept={(value) => onChange({ target: { name: props.name, value } })}
		overwrite
		/>
	);
});