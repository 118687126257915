import { Email } from '@mui/icons-material';
import { Collapse, ListItemIcon, MenuItem, Stack, TextField } from '@mui/material';
import axios from "axios";
import { FinamCities } from 'components/cities';
import Snackbar from 'hooks/snackbar';
import React, { useState } from 'react';
import { DialogTemplate } from './components/dialog';

export const AddMailReceiver = () => {
	const [data, setData] = useState({
		cityid: null,
		city_ru: null,
		name: null,
		email: null,
	});

	const handleClose = () => {
		setData({
			cityid: null,
			city_ru: null,
			name: null,
			email: null,
		});
	}

	const handleSubmit = async (e) => {
		const res = await axios.post('/city/mailreceiver/add', data);
		if(res.data) {
			Snackbar.toast(res.data.message, { variant: res.data.type });
		}
	}

	return(
		<DialogTemplate
		type="add"
		render={
		<MenuItem>
			<ListItemIcon><Email /></ListItemIcon>
			Добавить email получателя
		</MenuItem>
		}
		menuText="Добавить email получателя"
		contentText="После добавления email он сразу активируется и на него будут приходить письма. Если хотите контролировать этот процесс перейдите в раздел настройки города."
		onClose={handleClose}
		onSubmit={handleSubmit}
		routeHashLink={`/city/${data.city_ru}/settings#mail-receiver`}
		disabled={!data.cityid || !data.name || !data.email}
		>
			<FinamCities onChange={(e, value) => setData({...data, cityid: value.id, city_ru: value.city})} />

			<Collapse in={data.cityid ? true : false}>
				<Stack spacing={2} sx={{mt: 3}}>
					<TextField label="Название" name="name" value={data.name || ''} onChange={(e) => setData({...data, [e.target.name]: e.target.value})} />
					<TextField label="Email получателя" name="email" value={data.email || ''} onChange={(e) => setData({...data, [e.target.name]: e.target.value})}/>
				</Stack>
			</Collapse>
		</DialogTemplate>
	);
}