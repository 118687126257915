import React, {useState, useEffect} from 'react';
import { Link as RouterLink } from "react-router-dom";
import axios from 'axios';

import { observer } from "mobx-react-lite";
import { List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, Box, Backdrop, CircularProgress, useScrollTrigger, Slide, Tooltip, Badge, Popper, Fade, Paper, ClickAwayListener, ListItemAvatar, Avatar, Link, Divider, Skeleton } from '@mui/material';
import { Settings as SettingsIcon, Inbox, GitHub, Notifications, Pageview, FindInPage } from '@mui/icons-material';

import { useStores } from '@store';
import UserAvatar from 'hooks/userAvatar';
import TimeAgo from 'hooks/timeago';

export const ReleaseTemplate = (props) => {
	const {version} = JSON.parse(props.attr);

	return(
		<>
			<ListItemAvatar>
				<Avatar><GitHub /></Avatar>
			</ListItemAvatar>
			<ListItemText primary={
				<div>
					Вышло новое обновление сайта <b>{version}</b><br />
					Посмотреть изменения можете <Link to="/releases" component={RouterLink}>здесь</Link>
				</div>
			}/>
		</>
	)
};

export const ReplyMsgTemplate = observer((props) => {
	const {initiator, event} = props;
	const {message_id} = JSON.parse(props.attr);
	const [reply, setReply] = useState(null);

	const { users } = useStores();
	const user = users.findUser(initiator);

	useEffect(() => {
		if(event == "reply") {
			axios.get(`/chat/message/reply/${message_id}`).then(res => {
				setReply(res.data ? res.data[0] : {message: 'Сообщение не найдено или было удалено'});
			})
		}
	}, []);

	return(
		<>
		<ListItemAvatar>
			<UserAvatar userid={initiator} size="medium"/>
		</ListItemAvatar>
		<ListItemText primary={
			<div>
				<Link color="error">{user?.userName}</Link> ответил(а) вам:<br />
				{reply ? (
				<>{reply.message}<br /><TimeAgo date={reply.rr_time} title="" /></>
				) : <Skeleton variant="text"/>}
			</div>
		}/>
		</>
	);
});

export const ParserTemplate = (props) => {
	const {variant, message} = JSON.parse(props.attr);

	return(
		<>
		<ListItemAvatar>
			<Avatar><FindInPage /></Avatar>
		</ListItemAvatar>
		<ListItemText primary={
		<div>Сбой парсера <b>{variant}</b><br />{message}<br /><TimeAgo date={props.date} title="" /></div>
		}/>
		</>
	);
}