import PropTypes from "prop-types";
import React, { useState, } from 'react';
import axios from 'axios';
import {observer} from 'mobx-react-lite';

import {Button, Popover, Box, TextField, Avatar, Chip, CircularProgress} from '@mui/material';
import { Telegram, InsertEmoticon, Close } from '@mui/icons-material';
import { makeStyles, withStyles } from '@mui/styles';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart-lite';

import { useStores } from '@store';
import { ReplyPattern } from './comment';

const useStyles = makeStyles(theme => ({
	footer: {
		width: '100%',
		backgroundColor: "rgba(0,0,0,.03)",
		borderTop: "1px solid rgba(0,0,0,.125)",
	},
	button: {
		minWidth: 30,
		padding: "0 5px 0 5px",
		color: "#7b7b7b",
		opacity: "0.6",
		'&:hover': {
			opacity: 1,
			background: "unset",
		},
	},
	mention: {
		padding: "0 5px 0 5px",
		fontSize: "75%",
	},
	container: {
		width: 355,
		height: 426,
	},
}));

const CssTextField = withStyles({
	root: {
		'& .MuiInputBase-input': {
			paddingLeft: "5px",
			fontSize: "0.85rem",
		},
		'& .MuiInput-underline:before': {
			borderBottom: '2px solid transparent'
		},
		'& .MuiInput-underline:hover:before': {
			borderBottom: '2px solid transparent'
		}
	},
})(TextField);

export default observer(() => {
	const classes = useStyles();
``
	const { auth, chat, ws } = useStores();
	const [message, setMessage] = useState('');
	const [load, setLoad] = useState(false);

	const handleSendMessage = async (e) => {
		if(message.length <= 0 || /^\s+$/.test(message)) return;

		var data = {
			message: message,
			reply: chat.reply,
			rr_time: new Date(),
			user: auth.id,
		};

		//mentions.length = 0;
		setMessage('');
		setLoad(true);
		chat.reply = null;

		const res = await axios.post('/chat/message/send', data);

		if(res?.data.success) {
			data['id'] = res.data.id;
			ws.send('chat/sendmessage', data);
		}

		setLoad(false);
	}

	const onChange = (e) => {
		setMessage(e.target.value);
	}

	const keyPress = (e) => {
		if(e.keyCode == 13 && !e.shiftKey) {
			e.preventDefault();
			handleSendMessage();
		}
	}

	return(
		<div className={classes.footer}>
			{chat.reply && <ReplyPattern data={chat.reply} action={true}/>}
			<CssTextField
			fullWidth
			multiline
			variant="standard"
			value={message}
			placeholder="Напишите сообщение..."
			onChange={onChange}
			onKeyDown={keyPress}
			InputProps={{
				endAdornment: (
					<>
					<EmojiPicker setMessage={setMessage}/>
					<Button onClick={handleSendMessage} className={classes.button} disabled={load}>{load ? <CircularProgress size={20}/> : <Telegram />}</Button>
					</>
				),
			}}
			/>
			{/* <DisplayMentions/> */}
		</div>
	);
});

const DisplayMentions = observer(() => {
	const { mentions } = useStores();

	const handleDelete = (data) => () => {
		mentions.splice(mentions.findIndex(value => value.id === data.id), 1);
	}

	return(
		<>
		{mentions?.map(data =>
			<React.Fragment key={data.id}>
			<Chip
			clickable
			size="small"
			variant="outlined"
			icon={<Avatar>?</Avatar>}
			label={'@' + data.email_ob}
			onDelete={handleDelete(data)}
			/>
			</React.Fragment>
		)}
		</>
	);
});

const EmojiPicker = ({setMessage}) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const SelectEmoji = (emoji) => {
		setMessage(prev => prev + emoji.native);
	}

	return(
		<>
		<Button onClick={handleClick} className={classes.button}><InsertEmoticon/></Button>
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			transitionDuration={0}
		>
			<Picker title='Выберите смайл' emoji='point_up' native={true} showSkinTones={false} onClick={SelectEmoji} />
		</Popover>
		</>
	);
}
EmojiPicker.propTypes = {
    setMessage: PropTypes.func
}
