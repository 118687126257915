import { types, flow } from "mobx-state-tree";
import { Auth } from "../auth";

export const BalanceEntry = types.model("BalanceEntry", {
	cityid: types.number,
	cny_balance: types.number,
	cny_cet: types.number,
	cny_cet_ex: types.number,
	cny_coming: types.number,
	cny_expense: types.number,
	cny_start: types.number,
	eur_balance: types.number,
	eur_cet: types.number,
	eur_cet_ex: types.number,
	eur_coming: types.number,
	eur_expense: types.number,
	eur_start: types.number,
	num_operation: types.number,
	rub_balance: types.number,
	rub_cet: types.number,
	rub_cet_ex: types.number,
	rub_coming: types.number,
	rub_expense: types.number,
	rub_start: types.number,
	usd_balance: types.number,
	usd_cet: types.number,
	usd_cet_ex: types.number,
	usd_coming: types.number,
	usd_expense: types.number,
	usd_start: types.number,
	update: types.model({
		id: types.maybeNull(types.number),
		date: types.string,
	}),
})
.views(self => ({
	calcBalance(type) {
		var calc = self[type + 'start'] + self[type + 'coming'] - self[type + 'expense'] + self[type + 'cet'] - self[type + 'cet_ex'];
		return +calc.toFixed(2);
	},
}))
.actions(self => ({
	setValue(type, value) {
		self[type] = value;
	},

	setUserUpdate() {
		self.update.id = Auth.id;
		self.update.date = new Date().toString();
	},
}));