import { observable, toJS } from "mobx";
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Auth } from "./auth";
import { CitiesStore } from './cities/cities';
import { NotifyStore } from './notification';
import { QuotesStore } from "./quotes";
import { UsersStore } from './users';

export var Websocket = observable({
	socket: null,
	event: null,
	hash: null,
	msg: [],
	status: null,

	InitialSocket() {
		let socket = new ReconnectingWebSocket(process.env.WS);
		this.socket = socket;
		socket.onopen = () => {
			this.setStatus(socket.readyState);
			socket.send(JSON.stringify({event: "login", bc: "none", data: {
				userid: Auth.id,
			}}));
		};
		socket.onerror = () => {
			this.setStatus(socket.readyState);
		};
		socket.onclose = () => {
			this.setStatus(socket.readyState);
		};

		socket.onmessage = event => {
			var message = JSON.parse(event.data);
			var data = message.data;

			this.setEvent(message.event);
			this.setHash(message.hash);
			this.setMsg(data);

			CitiesStore.wsUpdate(data);
			Auth.wsUpdate();
			NotifyStore.wsUpdate();
			UsersStore.wsUpdate();
            QuotesStore.wsUpdate();
		};
	},

	setStatus(status) {
		this.status = status;
	},

	setEvent(event) {
		this.event = event;
	},

	setHash(hash) {
		this.hash = hash;
	},

	setMsg(msg) {
		this.msg = msg;
	},

	bind(event, callback) {
		if(event == this.event) {
			callback(toJS(this.msg));
		} else {
			return;
		}
	},

	send(event, data, bc) {
		this.status && this.socket.send(JSON.stringify({event: event, data: data, bc: bc}));
	}
});
