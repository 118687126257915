import React from 'react';
import {observer} from 'mobx-react-lite';
import store from 'store';
import {Paper} from '@mui/material';

import { Rnd } from "react-rnd";

import { useStores } from '@store';

import ChatHeader from "./header";
import ChatBlock from "./chatblock";
import ChatFooter from "./footer";

const ChatApp = observer(() => {
	const { chat } = useStores();

	const sizeLocal = store.get('chat-size');
	const positionLocal = store.get('chat-position');

	return (
		<>
		{chat.drag ?
			<Paper
			elevation={3}
			component={Rnd}
			//dragHandleClassName="draggable-title"
			bounds="window"
			minWidth={250}
			minHeight={250}
			onDragStop={(e, d) => {
				store.set('chat-position', {x: d.x, y: d.y})
			}}
			onResizeStop={(e, direction, ref, delta, position) => {
				store.set('chat-size', {width: ref.offsetWidth, height: ref.offsetHeight})
			}}
			default={{
				x: positionLocal?.x || 0,
				y: positionLocal?.y || 0,
				width: sizeLocal?.width || 250,
				height: sizeLocal?.height || 300
			}}>
				<ChatHeader />
				<ChatBlock />
				<ChatFooter />
			</Paper>
		:
			<>
			<ChatHeader />
			<ChatBlock />
			<ChatFooter />
			</>
		}
		</>
	);
});

export default ChatApp;