import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {Container, Grid, Typography, Box, Divider, IconButton, TextField, InputAdornment, Tooltip} from '@mui/material';
import {Visibility, VisibilityOff, Loop, VpnKey} from '@mui/icons-material';

export const Password = (props) => {
	const {onChange, passgen, ...inputs} = props;
	const [showpass, setShowpass] = useState(false);

	const handleGenpass = (e) => {
		const generate = GenericPassword();
		onChange(e, generate);
	}

	const handleChange = (e) => {
		onChange(e);
	}

	return(
		<TextField
		variant="outlined"
		type={showpass ? 'text' : 'password'}
		placeholder="Password"
		InputProps={{
			endAdornment: (
				<InputAdornment position="end">
					<IconButton
						aria-label="toggle password visibility"
						onClick={() => setShowpass(!showpass)}
						edge="end"
						size="large">
					{showpass ? <Visibility /> : <VisibilityOff />}
					</IconButton>
						{passgen &&
						<Tooltip title="Сгенерировать пароль" placement="top">
						<IconButton
							aria-label="generation password"
							onClick={handleGenpass}
							edge="end"
							size="large">
							<VpnKey />
						</IconButton>
						</Tooltip>
						}
				</InputAdornment>
			)
		}}
		onChange={handleChange}
		inputProps={{
			...props.inputProps,
			autoComplete: "off",
		}}
		{...inputs}
		/>
	);
}

function GenericPassword(length = 15) {
	var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*+<>ABCDEFGHIJKLMNOP1234567890";
	var pass = "";
	for (var x = 0; x < length; x++) {
		var i = Math.floor(Math.random() * chars.length);
		pass += chars.charAt(i);
	}
	return pass;
}