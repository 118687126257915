import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { Button, Typography, TextField, Box, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';

import AuthTemplate from './template';

const useStyles = makeStyles(theme => ({
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	alert: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
}));

export default (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [pass, setPass] = useState(null);
	const [status, setStatus] = useState("");

	const onSubmit = (e) => {
		e.preventDefault();
		axios.post('/auth/resetpass/changepass', {
			selector: props.query.get("selector"),
			token: props.query.get("token"),
			password: pass,
		}).then(res => {
			setStatus(res.data);

			if(res.data.type == "success") {
				history.push("/login");
				enqueueSnackbar(res.data.message, { variant: res.data.type });
			}
		});
	}

	return(
		<AuthTemplate textBlock="Сброс вашего пароля">
		<Box className={classes.alert}>
			{status.type == "danger" && <Alert severity="error">{status.message}</Alert>}
		</Box>
		<Typography variant="subtitle2">
			Введите новый пароль в форму ниже
		</Typography>

		<form className={classes.form} onSubmit={onSubmit}>
			<TextField
			variant="outlined"
			size="small"
			margin="normal"
			required
			fullWidth
			label="Новый пароль"
			type="password"
			autoComplete="new-password"
			onChange={(e) => setPass(e.target.value)}
			/>
			<Button fullWidth variant="contained" type="submit"	color="primary"	className={classes.submit}>Сбросить пароль</Button>
		</form>

		</AuthTemplate>
	);
}