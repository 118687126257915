import React, {useState, useEffect, useCallback, useRef} from "react";
import axios from "axios";
import {observer} from "mobx-react-lite";

import { Avatar, Button, Collapse } from '@mui/material';
import {AddAPhoto} from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

import Snackbar from 'hooks/snackbar';
import { ButtonLoader } from 'hooks/loader';
import { useStores } from '@store';

const useStyles = makeStyles(theme => ({
	large: {
		width: theme.spacing(32),
		height: theme.spacing(32),
		//marginBottom: theme.spacing(2),
		fontSize: "2.5rem",
	},
	label: {
		cursor: "pointer",
	},
	input: {
		display: "none",
	}
}));

const AvatarUploader = observer(({userid, avatar}) => {
	const classes = useStyles();
	const {authStore} = useStores();

	const input = useRef(null);

	const [loading, setLoading] = useState(false);
	const [preview, setPreview] = useState(null);
	const [photo, setPhoto] = useState(null);
	const [ava, setAva] = useState(avatar);

	const handleChange = (e) => {
		e.preventDefault();

		let reader = new FileReader();
		let file = e.target.files[0];
		let type = false;

		switch(file?.type) {
			case "image/jpeg": type = true; break;
			case "image/gif": type = true; break;
			case "image/pjpeg": type = true; break;
			case "image/png": type = true; break;
			default: type = false; break;
		}

		if(!type) {
			return Snackbar.error("Файл имеет неверный формат");
		}

		if(file?.size > 2097152) {
			return Snackbar.error("Файл не должен превышать 2 Mb");
		}

		reader.onload = () => {
			var image = new Image();
			image.src = reader.result;
			image.onload = () => {
				//console.log(image.width)
			}
		}
		reader.onloadend = () => {
			setPhoto(file);
			setPreview(reader.result);
		}

		file && reader.readAsDataURL(file)
	}

	const handleSubmit = (e) => {
		setLoading(true);
		var formData = new FormData();
		formData.append("file", photo);

		axios.post(`/settings/avatar/upload/${userid}`, formData, {headers: {
			"Content-Type": "multipart/form-data",
		}},).then(res => {
			if(res.data.type == "success") {
				Snackbar.success("Аватар добавлен");
				setAva(res.data.avatar);
				authStore.changeAvatar(res.data.avatar);
				setPreview(null);
			}

			setLoading(false);
		})
	}

	const DeleteAvatar = () => {
		axios.post(`/settings/avatar/delete/${userid}`).then(res => {
			if(res.data.type == "success") {
				setAva('');
				authStore.changeAvatar('');
			}
		});
	}

	return(
		<>
		<input id="ProfileAddPhoto" name="file" type="file" ref={input} onChange={handleChange} className={classes.input}/>
		<label htmlFor="ProfileAddPhoto" className={classes.label}>
			{preview ?
			<Avatar className={classes.large} variant="rounded" src={preview} /> :
			<Avatar className={classes.large} variant="rounded" src={`${process.env.IMG}/assets/img/avatars/${ava}`}>{!ava && <AddAPhoto style={{fontSize: "2em"}}/>}</Avatar>
			}
		</label>
		<Collapse in={preview && true} style={{width: '100%'}}>
			<Button
			fullWidth
			color="primary"
			variant="contained"
			onClick={handleSubmit}
			disabled={loading}
			>Сохранить аватар {loading && <ButtonLoader/>}
			</Button>
		</Collapse>
		{ava && <Button fullWidth onClick={DeleteAvatar} color="secondary">Убрать аватар</Button>}
		</>
	);
});

export default AvatarUploader;