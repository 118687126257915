import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {observer} from "mobx-react-lite";
import {Grid, FormControl, InputLabel, Select, MenuItem, Skeleton} from '@mui/material';

import { useStores } from '@store';

export default observer(({user, setUser}) => {
	const { auth } = useStores();
	const [roles, setRoles] = useState(null);

	useEffect(() => {
		axios.get('/user/allroles').then(res => {
			setRoles(res.data);
		});
	}, [user.id]);

	return(
		<>
		<Grid item xs={6}>
			{!roles ? <Skeleton height={70}/> :
			<FormControl variant="outlined" fullWidth>
				<InputLabel id="accessSelect">Уровень доступа</InputLabel>
				<Select
				labelId="accessSelect"
				id="accessSelectMain"
				value={user.role_id || ""}
				onChange={(e) => setUser({...user, role_id: e.target.value})}
				autoComplete="new-access-2"
				labelWidth={130}
				disabled={!auth.Leader}
				>
					{Object.keys(roles).map(key => {
						let disabled = null;
						if(user.id != 1 && roles[key].id == 1) disabled = true;
						return (<MenuItem key={key} value={roles[key].id} disabled={disabled}>{roles[key].name}</MenuItem>);
						}
					)}
				</Select>
			</FormControl>
			}
		</Grid>
		<Grid item xs={6}></Grid>
		</>
	);
});