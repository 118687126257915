import { types, flow } from "mobx-state-tree";

export const BoardEntry = types.model({
	cityid: types.number,
	bUSD: types.number,
	sUSD: types.number,
	sUSDover: types.number,
	bEUR: types.number,
	sEUR: types.number,
	sEURover: types.number,
	bCNY: types.number,
	sCNY: types.number,
	sCNYover: types.number,
	sign_date: types.string, // yyyy-mm-dd hh:mm:ss
	logo: types.string,
})
.views(self => ({

}))
.actions(self => ({
	setValue: (type, value) => {
		self[type] = value;
	},

	setSignDate: (date) => {
		// format yyyy-mm-dd
		var todayDate = new Date().toISOString().slice(0, 10);
		// format hh:mm:ss
		var toNowTime = (date.getHours()<10?'0':'')+date.getHours()+':'+(date.getMinutes()<10?'0':'')+date.getMinutes()+':00';

		self.sign_date = `${todayDate} ${toNowTime}`;
	}
}));