import React from "react";
import {Link as RouterLink} from 'react-router-dom';

import { Button, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(theme => ({
	root: {
		textAlign: "center",
	},
}));

export default class ErrorBoundary extends React.Component {
	state = { hasError: false };

	componentDidCatch(error, info) {
		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) {
			return(
				<RenderInfo />
			);
		}

		return this.props.children;
	}
}

const RenderInfo = () => {
	const classes = useStyles();

	return(
		<Box className={classes.root}>
			<Typography variant="h4">Ууупс, что-то пошло не так!</Typography>
			<Typography variant="subtitle1">Произошла критическая ошибка, страница размонтирована. Обратитесь к разработчику!</Typography>
			<Box display="flex" my={6} justifyContent="center">
				<img src="/assets/img/undraw_server_down_s4lk.svg" width="560px" height="auto" />
			</Box>
			<Button variant="outlined" color="primary" component={RouterLink} to="/currency">Вернуться назад</Button>
		</Box>
	);
}