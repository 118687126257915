import { getParent, types } from "mobx-state-tree";
import { QuotesStore } from "../quotes";

export const CurrencyEntry = types.model({
	cityid: types.number,
	bUSD: types.number,
	sUSD: types.number,
	bEUR: types.number,
	sEUR: types.number,
	bCNY: types.number,
	sCNY: types.number,
	update: types.model({
		id: types.maybeNull(types.number),
		date: types.string,
	}),
})
.views(self => ({
    get checkDiff() {
        let message = [];
        ["bUSD", "sUSD", "bEUR", "sEUR", "bCNY", "sCNY",].map(name => {
            let curname = name.substr(1).toLowerCase();
            let quote = QuotesStore.findQuote(curname);

            if(quote) {
                if(curname == "cny" && !getParent(self).cny) return;
                console.log(self[name], (quote.price + quote.diff))
                if(self[name] >= (quote.price + quote.diff) || self[name] <= (quote.price - quote.diff)) {
                    message.push([{type: name }]);
                }
            }
        });

        return message;
    },
}))
.actions(self => ({
	setValue: (type, value) => {
		self[type] = value;
	},
	setCurrencyInput: (data) => {
		self.update = data.update;

		Object.keys(data).forEach((key) => {
			if(key[0] == "b" || key[0] == "s") {
				self[key] = data[key];
			}
		});
	},
}));