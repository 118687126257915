import React, {useState} from 'react';
import { Link as RouterLink, useLocation } from "react-router-dom";

import { observer } from "mobx-react-lite";
import { IconButton, Tooltip, Badge } from '@mui/material';
import { GitHub } from '@mui/icons-material';
import { AppBarButton } from './style/iconbutton';

import { useStores } from '../../../hooks/user-store';

const ReleasesGithub = observer(() => {
	const location = useLocation();
	const { notify } = useStores();

	return(
		<Tooltip disableInteractive title="Вышедшие релизы">
			<div>
				<AppBarButton selected={location.pathname == "/releases"} to="/releases" component={RouterLink}>
				<Badge
				variant="dot"
				color="secondary"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				invisible={!notify.EventBool("release")}
				>
					<GitHub />
				</Badge>
				</AppBarButton>
			</div>
		</Tooltip>
	);
});

export default ReleasesGithub;