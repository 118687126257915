import React,{useState, useEffect} from 'react';
import PropTypes from "prop-types";
import axios from "axios";

import {
	Grid, Typography, Button, IconButton, TextField, Collapse,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	Box, CircularProgress,
	List, ListItem, ListItemText, MenuItem, ListItemIcon, Alert, Autocomplete, ToggleButton,
} from '@mui/material';
import { BookmarkAdd } from '@mui/icons-material';

export default () => {
	const handleClick = () => {
		axios.post('/parser/github/release/new');
	}

	return(
		<MenuItem onClick={handleClick}>
			<ListItemIcon><BookmarkAdd /></ListItemIcon>Пуш релиза
		</MenuItem>
	)
}