import { types, flow } from "mobx-state-tree";
import axios from "axios";
import {Websocket} from './websocket';

axios.defaults.baseURL = '/api';

export const Notify = types
	.model("Notify", {
		id: types.number,
		event: types.string,
		event_id: types.string,
	})
	.views(self => ({

	}))
	.actions(self => ({

	}));

export const Notifys = types
	.model("Notifys", {
		data: types.array(Notify),

	})
	.views(self => ({
		get EventCount() {
			return self.data?.length || 0
		},

		EventBool(event) {
			let data = self.data?.filter(item => item.event == event);

			return data?.length > 0 ? true : false;
		}
		/*findUser(id) {
			return self.data.find(item => item.id == id);
		},*/
	}))
	.actions(self => {
		const fetchData = flow(function* fetchData() {
			const response = yield axios.get("/notify/check");
			self.data = response.data;
			//self.isLoading = false;
		})

		function wsUpdate() {
			Websocket.bind('notify/update', (data) => {
				self.fetchData();
			});
		}

		const setUserView = () => {
			if(self.EventCount) {
				let events = self.data?.map(item => item.event_id);

				if(events) {
					axios.post('/notify/user/viewed', {event_id: events}).then(res => {
						res.data?.success && self.fetchData();
					});
				}
			}
		}

		return { fetchData, wsUpdate, setUserView }
	});

export const NotifyStore = Notifys.create({});