import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';
import { observer } from "mobx-react-lite";
import { useStores } from '@store';

import ChatComment from "./comment";
import { ComponentLoader } from 'hooks/loader';

export default observer(() => {
	const { ws, users } = useStores();

	const [message, setMessage] = useState(null);
	const El = useRef(null);

	useEffect(() => {
		axios.get("/chat/messages").then(res => setMessage(res.data));
	}, []);

	useEffect(() => {
		El.current.scrollToBottom();
	}, [message, users.isLoading]);

	useEffect(() => {
		ws.bind('chat/sendmessage', (data) => {
			const msg = message.concat(data);
			setMessage(msg);
		});

		ws.bind('chat/delete', (data) => {
			setMessage(message.filter(item => item.id != data.id));
		});
	}, [ws.hash]);

	return(
		<Scrollbars style={{ height: `100%` }} ref={El}>
			{message ?
				message.map((data, index) => <ChatComment data={data} message={message} key={index} />) :	<ComponentLoader />
			}
		</Scrollbars>
	);
});