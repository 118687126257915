import { Container, CssBaseline } from '@mui/material';
import { useStores } from '@store';
import axios from "axios";
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import MainTab from './general';

// const SettingsMain = () => {
// 	return(
// 		<Box display="flex">
// 			<SettingsMenu />
// 			<Box flexGrow={1}>

// 			</Box>
// 		</Box>
// 	);
// }

// export default SettingsMain;

export default observer(() => {
	const { auth } = useStores();
	const [data, setData] = useState(null);


	useEffect(() => {
		axios.get("/user/data/" + auth.id).then(res => {
			setData(res.data);
		});
	}, []);

	return(
		<>
		<CssBaseline />
		<Container maxWidth="lg">
			{data && <MainTab user={data} setUser={setData} />}
		</Container>
		</>
	);
});