import { Check, PersonAdd } from '@mui/icons-material';
import { LoadingButton } from "@mui/lab";
import { Box, Collapse, Fade, InputAdornment, ListItemIcon, MenuItem, Stack, TextField } from '@mui/material';
import { useStores } from '@store';
import axios from 'axios';
import { FinamCities, RussiaCities } from 'components/cities';
import { Access, Fullname } from 'components/forms';
import Snackbar from 'hooks/snackbar';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { DialogTemplate } from './components/dialog';

const initialData = {
    fullname: "",
    city: "",
    cityid: null,
    access: null,
};

export default observer(() => {
    const {ws} = useStores();
    let history = useHistory();

    const [email, setEmail] = useState({
        value: "",
        pending: false,
        response: {
            success: null,
            message: "",
        }
    });
    const [user, setUser] = useState(initialData);

    const handleClose = () => {
        setEmail({
            value: "",
            pending: false,
            response: {
                success: null,
                message: "",
            }
        });
        setUser(initialData);
    }

    const handleSubmit = async (e) => {
        let data = {
            email: email.value,
            name: user.fullname,
            role_id: user.access,
            city: user.city,
            cityid: user.cityid,
        };

        const res = await axios.post('/user/add', data);

        if(res){
            Snackbar.toast(res.data.message, {variant: res.data.type});
            if(res.data.success) {
                ws.send('user/add', res.data.userid);
                history.push(`/users/edit/${res.data.userid}`);
            }
        }
    }

    return(
        <DialogTemplate
        type="add"
        render={
        <MenuItem>
            <ListItemIcon><PersonAdd /></ListItemIcon>
            Добавить пользователя
        </MenuItem>
        }
        menuText="Добавить нового пользователя"
        contentText="Заполните поле адреса эл.почты, после чего появятся доп. настройки пользователя."
        onClose={handleClose}
        onSubmit={handleSubmit}
        disabled={!user.fullname || !user.access || !user.city}
        >
            <CheckMailContent email={email} setEmail={setEmail} />
            <Collapse in={email.response.success} sx={{mt: 2}} unmountOnExit>
                <EditForms data={user} setData={setUser}/>
            </Collapse>
        </DialogTemplate>
    )
});

const EditForms = ({data, setData}) => {
    return(
        <Stack spacing={3}>
            <Fullname value={data.fullname} onChange={(e) => setData({...data, fullname: e.target.value})}/>
            <div>
                <Access value={data.access} onChange={(e) => setData({...data, access: e.target.value, cityid: ""})} sx={{minWidth: 300}}/>
            </div>
            <Box sx={{maxWidth: 500}}>
                <RussiaCities value={data.city} onChange={(e, value) => setData({...data, city: value.city, cityid: ""})}/>
            </Box>
            <Collapse in={data.access === 2097152 && data.city} unmountOnExit>
                <Box sx={{maxWidth: 350}}>
                    <FinamCities value={data.cityid} onChange={(e, value) => setData({...data, cityid: value.id})} onlyCity={data.city}/>
                </Box>
            </Collapse>
        </Stack>
    );
}

// export default () => {
// 	const {ws} = useStores();
// 	const [email, setEmail] = useState({
// 		value: "",
// 		pending: false,
// 		response: {
// 			success: null,
// 			message: "",
// 		}
// 	});
// 	const [names, setNames] = useState({n1: "", n2: "", n3: ""});
// 	const [user, setUser] = useState({
// 		role_id: null,
// 		city: null,
// 		cityid: null,
// 	});

// 	const [open, setOpen] = useState(false);
// 	const [load, setLoad] = useState(false);

// 	const handleOpen = () => setOpen(true);
// 	const handleClose = () => {
// 		setOpen(false);
// 		setEmail({
// 			value: "",
// 			pending: false,
// 			response: {
// 				success: null,
// 				message: "",
// 			}
// 		});
// 		setNames({n1: "", n2: "", n3: ""});
// 		setUser({
// 			role_id: null,
// 			city: null,
// 			cityid: null,
// 		});
// 	}

// 	const handleSubmit = async (e) => {
// 		setLoad(true);

// 		const res = await axios.post('/user/add', {
// 			email: email.value,
// 			name: `${names.n1} ${names.n2} ${names.n3}`,
// 			role_id: user.role_id,
// 			city: user.city,
// 			cityid: user.cityid,
// 		});

// 		if(res){
// 			Snackbar.toast(res.data.message, {variant: res.data.type});
// 			res.data.success && ws.send('user/add', res.data.userid);
// 		}
// 		setLoad(false);
// 		handleClose();
// 	}

// 	return(
// 		<>
// 		<MenuItem onClick={handleOpen}><ListItemIcon><PersonAdd /></ListItemIcon>Добавить пользователя</MenuItem>
// 		<Dialog	open={open}	onClose={handleClose}>
// 			<DialogTitle>Добавление пользователя</DialogTitle>
// 			<DialogContent>
// 				<DialogContentText>
// 					Заполните поле адреса эл.почты, после чего появятся доп. настройки пользователя.
// 				</DialogContentText>

// 				<CheckMailContent email={email} setEmail={setEmail} />

// 				<Collapse in={email.response.success}>
// 					<Box sx={{my: 5}}>
// 						{Object.entries(names).map(([key, value]) => <UserNamesField type={key} names={names} setNames={setNames} key={key}/>)}
// 					</Box>

// 					<Box sx={{my: 3}}>
// 						<UserAccessSelect user={user} setUser={setUser}/>
// 					</Box>
// 					<Box>
// 						<RussiaCities
// 						size="small"
// 						onChange={(e, value) => setUser({...user, city: value.city})}
// 						/>
// 					</Box>
// 				</Collapse>
// 			</DialogContent>
// 			<DialogActions>
// 				<Button onClick={handleClose} color="primary">
// 					Отменить
// 				</Button>
// 				<LoadingButton onClick={handleSubmit} color="primary" loading={load} disabled={!user.role_id || !user.city}>
// 					Добавить
// 				</LoadingButton>
// 			</DialogActions>
// 		</Dialog>
// 		</>
// 	);
// };

const CheckMailContent = ({email, setEmail}) => {

    const handleCheck = async (event) => {
        event.preventDefault();
        setEmail({...email, pending: true});

        const res = await axios.get(`/user/check/${email.value}`);
        if(res) {
            setEmail({...email, response: res.data, pending: false});
        } else {
            setEmail({...email, pending: false});
        }
    }

    return(
        <form id="addUserForm" onSubmit={handleCheck}>
            <TextField
            type="email"
            label="Адрес эл.почты"
            autoComplete="new-user"
            required
            fullWidth
            autoFocus
            onChange={(e) => setEmail({...email, value: e.target.value})}
            InputProps={{
                endAdornment: (
                    <Fade in={email.value.length > 0 ? true : false}>
                        <InputAdornment position="end">
                            <LoadingButton
                            type="submit"
                            loading={email.pending}
                            disabled={email.response.success}
                            >
                                {email.response.success ? <Check /> : "ПРОВЕРИТЬ"}
                            </LoadingButton>
                        </InputAdornment>
                    </Fade>
                ),
            }}
            error={email.response.success == false || false}
            helperText={email.response.message}
            disabled={email.response.success || false}
            />
        </form>
    );
}