import axios from 'axios';
import { getParent, types } from "mobx-state-tree";
import { Websocket } from '../websocket';

export const AutoRobotEntry = types.model({
	cityid: types.number,
	Fri: types.union(types.number, types.boolean),
	Mon: types.union(types.number, types.boolean),
	Sat: types.union(types.number, types.boolean),
	Sun: types.union(types.number, types.boolean),
	Thu: types.union(types.number, types.boolean),
	Tue: types.union(types.number, types.boolean),
	Wed: types.union(types.number, types.boolean),
	bUSD: types.maybe(types.number),
	sUSD: types.maybe(types.number),
	bEUR: types.maybe(types.number),
	sEUR: types.maybe(types.number),
	bCNY: types.maybe(types.number),
	sCNY: types.maybe(types.number),
	mail: types.union(types.number, types.boolean),
	delay: types.maybeNull(types.number),
	beginningTime: types.maybeNull(types.optional(types.string, "00:00:00")),
	endTime: types.maybeNull(types.optional(types.string, "00:00:00")),
	enable: types.number,
	status: types.model({
		active: types.optional(types.boolean, false),
		percent: types.optional(types.number, 0),
		start: types.maybe(types.Date),
		end: types.maybe(types.Date),
	})
	// update: types.model({
	// 	id: types.maybeNull(types.number),
	// 	date: types.string,
	// }),
})
.views(self => ({
	get errorDate() {
		var start = new Date(`1970-01-01 ${self.beginningTime}`);
		var end = new Date(`1970-01-01 ${self.endTime}`)

		return start.getTime() > end.getTime();
	},

	todayFormat(date) {
		var item;
		switch(date.getDay()) {
			case 1: item = "Mon"; break;
			case 2: item = "Tue"; break;
			case 3: item = "Wed"; break;
			case 4: item = "Thu"; break;
			case 5: item = "Fri"; break;
			case 6: item = "Sat"; break;
			case 0: item = "Sun"; break;
		}

		return item;
	},
}))
.actions(self => ({
	setValue: (type, value) => {
		self[type] = value;
	},

	setValueReactive: (type, value) => {
		self[type] = value;

		let data = {
			cityid: self.cityid,
			name: type,
			value: value,
		}

		axios.post('/city/change/autorobot', data).then(res => {
			res?.data.success && Websocket.send('autorobot/change', data);
		});
	},

	setTime: (type, value) => {
		if(value) {
			let hours = String(value.getHours()).padStart(2, "0");
			let minutes = String(value.getMinutes()).padStart(2, "0");

			return self[type] = `${hours}:${minutes}:00`;
		}
	},

	checkStatus: () => {
		var now = new Date();
		var utc = getParent(self)?.GMT - (now.getTimezoneOffset() / 60 * -1);
			now.setHours(now.getHours() + utc);
		var today = self.todayFormat(now);

		if(!self.errorDate && self.enable && self[today]) {
			var exMin = self.beginningTime?.split(':');
			var exMax = self.endTime?.split(':');

			if(exMin || exMax) {
				var MIN = new Date();
					MIN.setHours(MIN.getHours() + utc); // Добавляет часы, относительно часового пояса города
					MIN.setHours(exMin[0], exMin[1], "00"); // Устанавливает время из базы

                var MAX = new Date();
					MAX.setHours(MAX.getHours() + utc);
					MAX.setHours(exMax[0], exMax[1], "00");
			} else return;

			if(now.getTime() > MIN.getTime() && now.getTime() < MAX.getTime()) {
				self.status.active = true;
				self.status.percent = Math.floor((now - MIN) * 100 / (MAX - MIN));
				self.status.start = MIN;
				self.status.end = MAX;

			} else {
				self.status.active = false;
				self.status.percent = 0;
			}
		} else {
			self.status.active = false;
			self.status.percent = 0;
		}
	},
}));