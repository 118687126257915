import React, {useState} from 'react';
import { observer } from "mobx-react-lite";
import { Box, Badge, Popper, Fade, Paper, ClickAwayListener } from '@mui/material';
import { Notifications } from '@mui/icons-material';

import { AppBarButton } from './style/iconbutton';
import { useStores } from '@store';
import { NotifyContent } from './notification/content';

const NotificationIcon = observer(() => {
	const { notify } = useStores();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		notify.setUserView();
	};

	const handleClose = (event) => {
		setAnchorEl(null);
	}

	const open = Boolean(anchorEl);
	const id = open ? 'transitions-popper' : undefined;

	return(
		<ClickAwayListener onClickAway={handleClose}>
			<div>
				<AppBarButton
				selected={open}
				onClick={handleClick}
				>
					<Badge
					color="secondary"
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					badgeContent={notify.EventCount}
					>
						<Notifications />
					</Badge>
				</AppBarButton>

				{anchorEl && <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end" transition disablePortal>
				{({ TransitionProps }) => (
				<Fade {...TransitionProps} timeout={350}>
					<Box width={480} component={Paper}>
						<NotifyContent />
					</Box>
				</Fade>
				)}
				</Popper>
				}
			</div>
		</ClickAwayListener>
	);
});


export default NotificationIcon;