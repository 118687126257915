import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';

export const DialogTemplate = (props) => {
	const {type, menuIcon, menuText, contentText, routeHashLink, children, disabled, render, maxWidth, noSubmitClose, onClose, ...other} = props;
	const [open, setOpen] = useState(false);
	const [load, setLoad] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	}

	const handleClose = (e) => {
		onClose && onClose(e);
		setOpen(false);
	}

	const handleSubmit = async () => {
		setLoad(true);
		await props.onSubmit();
		setLoad(false);
		!noSubmitClose && handleClose();
	}

	const ButtonAcceptText = () => {
		switch(type) {
			case "confirmation": return "Согласен";
			case "add": return "Добавить";
			default: return "Принять";
		}
	}

	return(
		<>
		<div onClick={handleOpen}>{render}</div>
		<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={maxWidth}>
			<DialogTitle>{menuText}</DialogTitle>
			<DialogContent>
				<DialogContentText sx={{mb: 2}} variant="subtitle2">
					{contentText}
				</DialogContentText>
				{children}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Отменить
				</Button>
				<LoadingButton
				component={HashLink}
				smooth
				to={routeHashLink || ''}
				color="success"
				onClick={handleSubmit}
				loading={load}
				disabled={disabled}
				>
					{ButtonAcceptText()}
				</LoadingButton>
			</DialogActions>
		</Dialog>
		</>
	);
}