import React, {useState, useEffect} from 'react';
import axios from "axios";

import { Popover, Box, Backdrop, CircularProgress } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as LinkIcon} from '@mui/icons-material';
import { AppBarButton } from 'app/layout/appbar/style/iconbutton';
import Container from './container';

const useStyles = makeStyles(theme => ({
	container: {
		width: 500,
		minHeight: 220,
		maxHeight: 400,
	},
}));

export default () => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return(
		<>
		<AppBarButton selected={open} onClick={handleClick}>
			<LinkIcon/>
		</AppBarButton>
		<Popover
			id={open ? 'simple-popover' : undefined}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
			}}
		>
			<Box className={classes.container}>
				<Container />
			</Box>
		</Popover>
		</>
	);
}