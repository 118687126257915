import React, {useState} from 'react';
import { Link as RouterLink, useLocation } from "react-router-dom";
import Axios from 'axios';

import { observer } from "mobx-react-lite";
import { Menu, MenuItem, Button, ListItemIcon, Box } from '@mui/material';
import { ArrowDropDown, ExitToApp, Settings as SettingsIcon } from '@mui/icons-material';
import { AppBarButton } from './style/iconbutton';

import { useStores } from '@store';
import UserAvatar from 'hooks/userAvatar';
import { AuthAccess } from 'hooks/access';

const ProfileMenu = observer(() => {
	const { authStore, ws } = useStores();
	const location = useLocation();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleProfile = event => {
		setAnchorEl(event.currentTarget);
	}

	const handleClose = () => {
		setAnchorEl(null);
	}

	const handleQuit = () => {
		Axios.post('/auth/logout').then(res => {
			res.data.type == "success" && window.location.replace("/login");
		});
	}

	const handleF5 = () => {
		ws.send('user/f5', {success: true});
	}

	return(
		<>
		<AppBarButton onClick={handleProfile}>
			<UserAvatar userid={authStore.id} size="small"/> <Box pl={1} fontWeight="bold">{authStore.Name}</Box> <ArrowDropDown />
		</AppBarButton>

		<Menu
		anchorEl={anchorEl}
		open={Boolean(anchorEl)}
		onClose={handleClose}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		>
			<MenuItem onClick={handleClose} to={{pathname: `/settings`, /*state: { background: location }*/}} component={RouterLink}>
				<ListItemIcon><SettingsIcon/></ListItemIcon>Настройки
			</MenuItem>
			<AuthAccess access="Developer">
				<MenuItem onClick={handleF5}>
					User F5
				</MenuItem>
			</AuthAccess>
			<MenuItem onClick={handleQuit}><ListItemIcon><ExitToApp/></ListItemIcon>Выйти</MenuItem>
		</Menu>
		</>
	);
});

export default ProfileMenu;