import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import {useList, useHover, useHoverDirty} from 'react-use';
import { useSnackbar } from 'notistack';
import ContentEditable from 'react-contenteditable';
import { Scrollbars } from 'react-custom-scrollbars';

import { IconButton, Button, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, TextField, Typography, Box, CircularProgress, Fade, Tooltip, Link, ButtonBase } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as LinkIcon, Delete as DeleteIcon, OpenInBrowser, Inbox as InboxIcon, AddCircle} from '@mui/icons-material';

import {ComponentLoader} from '../../hooks/loader';
import { useStores } from '../../hooks/user-store';

const useStyles = makeStyles(theme => ({
	List: {
		'&:hover': {
			backgroundColor: "rgba(0, 0, 0, 0.070)",
		},
	},
	noData: {
		display: "flex",
		textAlign: "center",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		flexDirection: "column",
		paddingRight: "40px",
		paddingLeft: "40px",
	},
}));

export default () => {
	const classes = useStyles();
	const { authStore } = useStores();

	const [saveurl, { set, push, removeAt }] = useList(null);
	const [load, setLoad] = useState(true);

	useEffect(() => {
		axios.get('/user/saveurl/' + authStore.id)
		.then(res => {
			set(res.data);
			setLoad(false);
		});
	}, []);
	return(
		<>
		{load ? <ComponentLoader/> :
			<Scrollbars style={{ width: 500, height: 400 }}>
			<List dense={true}>
			{saveurl?.length > 0 ?
			<>
				{saveurl.map((data, index) =>
					<SaveUrlItem data={data} index={index} removeAt={removeAt} key={data.id} />
				)}
				<Box px={1} display="flex" justifyContent="center"><Add push={push}/></Box>
			</> :
			<Box className={classes.noData}>
				<Box><InboxIcon style={{ fontSize: 40 }} color="action"/></Box>
				<Box fontWeight={500} color="text.secondary" mb={3}>В данный момент у вас нет сохраненых ссылок. Вы можете добавить их через форму.</Box>
				<Add push={push}/>
			</Box>
			}
			</List>
			</Scrollbars>
		}
		</>
	);
}

const SaveUrlItem = ({data, index, removeAt}) => {
	const classes = useStyles();
	const [hover, setHover] = useState(false);

	const refdata = useRef({
		discription: data.discription,
		url: data.url
	});

	const onDelete = () => {
		axios.post('/user/saveurl/del', {
			id: data.id
		}).then(res => {
			if(res.data.type == "success") {
				removeAt(index);
			}
		});
	}

	const onChange = name => event => {
		refdata.current[name] = event.target.value;
	}

	const onBlur = state => event => {
		axios.post('/user/saveurl/change', {
			id: data.id,
			discription: refdata.current.discription,
			url: refdata.current.url,
		});
	}

	return (
        <div
		onMouseEnter={() => setHover(true)}
		onMouseLeave={() => setHover(false)}
		>
		<ListItem className={classes.List}>
			<ListItemText
			primary={<ContentEditable html={refdata.current.discription} onBlur={onBlur("discription")} onChange={onChange("discription")} className="editable"/>}
			secondary={<Typography component={ContentEditable} html={refdata.current.url} onBlur={onBlur("url")} onChange={onChange("url")} noWrap variant="caption" display="block" className="editable"/>}

			style={{maxWidth: "60%"}}
			/>
			<Fade in={hover} timeout={0}>
				<ListItemSecondaryAction>
					<Tooltip disableInteractive title="Открыть страницу">
						<IconButton target="_blank" rel="noopener" href={data.url} size="large"><OpenInBrowser/></IconButton>
					</Tooltip>
					<IconButton edge="end" aria-label="delete" onClick={onDelete} size="large">
						<DeleteIcon />
					</IconButton>
				</ListItemSecondaryAction>
			</Fade>
		</ListItem>
		</div>
    );
}

const Add = ({push}) => {
	const { enqueueSnackbar } = useSnackbar();

	const [view, setView] = useState(false);
	const [name, setName] = useState("");
	const [url, setUrl] = useState("");

	const onSubmit = (event) => {
		event.preventDefault();
		axios.post('/user/saveurl/add', {
			discription: name,
			url: url,
		}).then(res => {
			if(res.data.type == "success") {
				push({
					id: res.data.id,
					discription: name,
					url: url
				});
			} else {
				enqueueSnackbar(res.data.message, { variant: res.data.type });
			}
		});

		setUrl("");
		setName("");
	}

	return(
		<Box>
		{view ?
		<Fade in={view} timeout={500}>
			<form id="addUserUrls" onSubmit={onSubmit}>
			<Box display="flex">
				<Box pr={1}>
					<TextField
					onChange={(e) => setName(e.target.value)}
					value={name}
					variant="standard"
					label="Название"
					size="small"
					autoComplete="new-pass"
					required/>
				</Box>
				<Box>
					<TextField
					onChange={(e) => setUrl(e.target.value)}
					value={url}
					variant="standard"
					label="URL адрес"
					size="small"
					autoComplete="new-pass"
					required/>
				</Box>

				<IconButton type="submit" size="small" style={{padding: "8px"}}><AddCircle/></IconButton>
			</Box>
			</form>
		</Fade>
		: <Button variant="outlined" onClick={() => setView(true)} startIcon={<AddCircle/>}>Добавить ссылку</Button>
		}
		</Box>
	);
}