
import React,{useState, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useCopyToClipboard} from 'react-use';

import {
	Tooltip, Typography, Button, IconButton, Divider,
	Box, CircularProgress, Popover,
	List, ListItem, ListItemText, ClickAwayListener,
} from '@mui/material';
import { Share, FileCopy, NavigateNext } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { AppBarButton } from './style/iconbutton';

const useStyles = makeStyles(theme => ({
	container: {
		maxWidth: "500px",
	},
	button: {
		textTransform: 'none',
	}
}));


export default () => {
	const classes = useStyles();
	const local = window.location;
	const http = `${local.protocol}//${local.hostname}${local.port ? `:${local.port}` : ""}`;

	const [API, setAPI] = useState([
		{url: `${http}/api/public/currency/txt`, format: 'TXT'},
		{url: `${http}/api/public/currency/json`, format: 'JSON'},
		{url: `${http}/api/public/currency/xml`, format: 'XML'},
	]);

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'api-main' : undefined;

	return(
		<>
		<Tooltip disableInteractive title="API ссылки">
			<div>
				<AppBarButton selected={Boolean(anchorEl)} onClick={handleClick}><Share /></AppBarButton>
			</div>
		</Tooltip>

		<Popover
		id={id}
		open={open}
		anchorEl={anchorEl}
		onClose={handleClose}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'left',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'left',
		}}
		>
		<Box p={2} className={classes.container}>
			<Typography variant="h6" gutterBottom><Share color="action" fontSize="small"/> Общий API</Typography>
			<Typography variant="caption" display="block" gutterBottom>
				API в 3 разных форматах, со списком городов и курсами.<br/>
				Примечание: чтобы скопировать ссылку, достаточно просто нажать.
			</Typography>
			{API.map(data =>
				<ButtonsUrl data={data} key={data.format} />
			)}
			<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
				<Button component={RouterLink} to="/public/api" endIcon={<NavigateNext />} onClick={handleClose}>Посмотреть все</Button>
			</Box>
		</Box>
		</Popover>
		</>
	);
}

const ButtonsUrl = ({data}) => {
	const classes = useStyles();

	const [state, copyToClipboard] = useCopyToClipboard();
	const [tool, setTool] = useState(false);
	const handleTooltipClose = () => {
		setTool(false);
	};

	const handleTooltipOpen = () => {
		setTool(true);
	};

	return(
		<>
		<Tooltip
		disableInteractive
		PopperProps={{
			disablePortal: true,
		}}
		leaveDelay={1000}
		onClose={handleTooltipClose}
		open={tool}
		placement="top"
		title="Скопировано в буфер обмена"
		>
			<Button
				onClick={() => {
					copyToClipboard(data.url);
					handleTooltipOpen();
				}}
				startIcon={data.format}
				endIcon={<FileCopy color="action"/>}
				className={classes.button}
				fullWidth>
				{data.url}
			</Button>
		</Tooltip>
		<Divider />
		</>
	);
}