
import { Cloud, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { blue } from '@mui/material/colors';
import { makeStyles, useTheme } from '@mui/styles';
import { useStores } from '@store';
import { observer } from "mobx-react-lite";
import ChatApp from 'modules/chat/main';
import React, { useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "../router/router";
import AppTopbar from './appbar/appbar';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	logo: {
		fontSize: "1.5em",
		lineHeight: "48px",
		paddingLeft: "16px",
	},
	logoImg: {
		verticalAlign: "middle",
		width: "40px",
	},
	drawerPaper: {
		width: drawerWidth,
		overflowY: "unset",
	},
	drawerActive: {
		'&$selected': {
		color: blue[600],
		fontWeight: 600,
		borderLeft: `5px solid ${blue[600]}`,
		transition: theme.transitions.create(['border', 'color'], { duration: 300 })
		}
	},
	selected: {},
}));

const DrawerMenu = () => {
	const classes = useStyles();
	const theme = useTheme();

	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawer = () => {
		setMobileOpen(!mobileOpen);
	};

	return(
		<>
		<AppTopbar handleDrawer={handleDrawer}/>
		<Box className={classes.drawer}>
			<Drawer
			variant="temporary"
			anchor={theme.direction === 'rtl' ? 'right' : 'left'}
			open={mobileOpen}
			onClose={handleDrawer}
			ModalProps={{keepMounted: true,}}
			classes={{paper: classes.drawerPaper,}}
			sx={{ display: { xs: 'block', sm: 'none' } }}
			>
				<DrawerContent />
			</Drawer>

			<Drawer
			classes={{paper: classes.drawerPaper,}}
			sx={{ display: { xs: 'none', sm: 'block' } }}
			variant="permanent"
			open
			>
				<DrawerContent />
				<ChatApp/>
			</Drawer>
		</Box>
		</>
	);
};

const DrawerContent = observer(() => {
	const classes = useStyles();
	const { authStore } = useStores();

	return(
		<>
		<div className={classes.logo}>
			<img src="/assets/img/logo-finam.png" alt="лого" className={classes.logoImg} /> Банк Финам
		</div>
		<Divider />
		<List style={{fontSize: 14}} disablePadding>
			{routes.map(route => {
			let disabled = route.access === undefined ? true : authStore[route.access];

			if(route.visibility && !authStore[route.visibility]) return;
			return route.icon && <DrawerItem route={route} disabled={disabled} key={route.name}/>
			})}
		</List>
		</>
	);
});

const DrawerItem = observer(({route, disabled}) => {
	const classes = useStyles();
	const location = useLocation();
	const [open, setOpen] = useState(false);

	return <>
    <ListItem
    button
    component={NavLink}
    to={route.path}
    selected={location.pathname == route.path}
    classes={{root: classes.drawerActive, selected: classes.selected}}
    disabled={!disabled}
    >
        <ListItemIcon>{route.icon}</ListItemIcon>
        <ListItemText disableTypography={true} primary={route.name} />
        {route.path == "/currency" &&
        <ListItemSecondaryAction>
            <IconButton onClick={() => setOpen(!open)} size="large">{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
        </ListItemSecondaryAction>
        }
    </ListItem>
    {route.path == "/currency" &&
    <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
            <ListItem
            sx={{ pl: 4 }}
            button
            component={NavLink}
            to="/parser"
            selected={location.pathname == "/parser"}
            classes={{root: classes.drawerActive, selected: classes.selected}}
            >
                <ListItemIcon><Cloud /></ListItemIcon>
                <ListItemText primary="Парсер" />
            </ListItem>
        </List>
    </Collapse>
    }
    </>;
});

export default DrawerMenu;