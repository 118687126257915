import { TextField } from "@mui/material";
import React, { FunctionComponent } from 'react';

export const Fullname: FunctionComponent<typeof TextField> = props => {
	return(
		<TextField
		required
		type="text"
		label="ФИО"
		placeholder="Фамилия Имя Отчество"
		inputProps={{
			...props.inputProps,
			autoComplete: "off",
		}}
		InputProps={{
			//inputComponent: FullNameMask,
		}}
		{...props}
		/>
	);
}

// const FullNameMask = React.forwardRef(function TextMaskCustom(props, ref) {
// 	const { onChange, ...other } = props;
// 	return (
// 		<IMaskInput
// 		{...other}
// 		autoComplete="off"
// 		//mask={/^d{3}[a-zA-Z]{3}$/g}
// 		inputRef={ref}
// 		onAccept={(value) => onChange({ target: { name: props.name, value } })}
// 		overwrite
// 		/>
// 	);
// });