import React, {useState, useEffect} from 'react';
import axios from "axios";
import {observer} from 'mobx-react-lite';

import { Container, Grid, Typography, TextField, MenuItem, Autocomplete, Skeleton } from '@mui/material';

import {useStores} from '@store';

export default ({user, setUser}) => {
	const [geo, setGeo] = useState(null);

	useEffect(() => {
		axios.get('/cities/russia').then(res => {
			setGeo(res.data);
		});
	}, [user.id]);

	return(
	<>
	<Grid item xs={6}>
	{geo ?
	<Autocomplete
		id="fieldCity"
		options={geo}
		disableClearable={true}
		getOptionLabel={option => option.city}
		renderOption={(props, option, { selected }) => (
			<li {...props}>
			<Grid container>
				<Grid item xs={6}>{option.city}</Grid>
				<Grid item xs={6}>
					<Typography variant="caption">{option.region}</Typography>
				</Grid>
			</Grid>
			</li>
		)}
		value={geo.find(item => item.city == user.city) || null}
		style={{ width: "100%" }}
		onChange={(e, value) => setUser({...user, city: value.city, region: value.region})}
		renderInput={params => (
			<TextField
			{...params}
			inputProps={{
				...params.inputProps,
				autoComplete: 'fuckshit',
			}} label="Город" variant="outlined" fullWidth />
		)}
	/> : <Skeleton height={70}/>}
	</Grid>
	{user.role_id == 2097152 &&
	<Grid item xs={6}>
		{geo ? <SelectOffice user={user} setUser={setUser} /> : <Skeleton height={70}/>}
	</Grid>
	}
	</>
	);
}

const SelectOffice = observer(({user, setUser}) => {
	const {cities} = useStores();

	return(
		<TextField
		id="standard-select-currency"
		select
		variant="outlined"
		label="Офис"
		value={user.cityid}
		onChange={(event) => setUser({...user, cityid: event.target.value})}
		helperText="Выбор офиса для пользователя"
		>
		{cities.offices(user.city).map((option) => (
			<MenuItem key={option.id} value={option.id}>
				{option.address}
			</MenuItem>
		))}
		</TextField>
	);
});
