import React, {useCallback} from 'react';
import {Grid, TextField} from '@mui/material';

export default ({user, setUser}) => {
	const ChangeMail = useCallback(e => setUser({...user, email: e.target.value}), []);

	return(
		<Grid item xs={12}>
			<TextField
			required
			label="Email"
			variant="outlined"
			type="email"
			value={user.email}
			onChange={ChangeMail}
			autoComplete="new-email-1"
			style={{minWidth: "400px"}}
			/>
		</Grid>
	);
}