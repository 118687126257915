import React,{useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";

import {
	Grid, Typography, Button, IconButton, TextField, Collapse,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	Box, CircularProgress,
	List, ListItem, ListItemText, MenuItem, ListItemIcon, Alert, Autocomplete, ToggleButton,
} from '@mui/material';

import Snackbar from 'hooks/snackbar';
import { LoadingButton } from '@mui/lab';
import { RussiaCities } from 'components/cities';
import { CloudDownload } from '@mui/icons-material';

export default () => {
	let history = useHistory();

	const [open, setOpen] = useState(false);
	const [city, setCity] = useState(null);
	const [load, setLoad] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	}

	const handleClose = () => {
		setOpen(false);
		setCity(null);
		setLoad(false);
	}

	const onSubmit = async (e) => {
		setLoad(true);
		const res = await axios.post('/parser/custom/add', {
			city_ru: city.city,
		});
		res && Snackbar.toast(res.data.message, { variant: res.data.type });
		if(res?.data.success) {
			handleClose();
			history.push('/parser');
		}
		setLoad(false);
	}

	return (
		<>
		<MenuItem onClick={handleOpen}><ListItemIcon><CloudDownload /></ListItemIcon>Добавить парсер</MenuItem>
		<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm" aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Добавить новый парсер</DialogTitle>
			<DialogContent>
			<DialogContentText>
				Выберите город из списка и заполните название локации после.
			</DialogContentText>
				<RussiaCities onChange={(e, value) => setCity(value)} />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Отменить
				</Button>
				<LoadingButton onClick={onSubmit} color="success" loading={load} disabled={!city}>
					Добавить
				</LoadingButton>
			</DialogActions>
		</Dialog>
		</>
	);
}