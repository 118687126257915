import React,{useState, useEffect} from 'react';
import PropTypes from "prop-types";
import axios from "axios";

import {
	Grid, Typography, Button, IconButton, TextField, Collapse,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	Box, CircularProgress,
	List, ListItem, ListItemText, MenuItem, ListItemIcon, Alert, Autocomplete, ToggleButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {AddLocation} from '@mui/icons-material';

import { useTimeout, useAsync } from 'react-use';
import Snackbar from 'hooks/snackbar';
import { LoadingButton } from '@mui/lab';
import { RussiaCities } from 'components/cities';

const useStyles = makeStyles(theme => ({
	utc: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	selectUtc: {
		margin: theme.spacing(1),
	},
}));

export default () => {
	const [open, setOpen] = useState(false);
	// Для добавления
	const [city, setCity] = useState(null);
	const [newCity, setNew] = useState(null);
	const [UTC, setUTC] = useState(null);
	const [load, setLoad] = useState(false);
	const [response, setResponse] = useState(null);

	const handleOpen = () => {
		setOpen(true);
	}

	const handleClose = (e) => {
		e.preventDefault();
		setOpen(false);
		setCity(null);
		setNew(null);
		setLoad(false);
	}

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoad(true);
		const res = await axios.post('/cities/add', {
			city_en: city.city_en,
			city_ru: city.city,
			address: newCity,
			utc: UTC,
		});
		res && Snackbar.toast(res.data.message, { variant: res.data.type });
		handleClose(e);
	}

	return (
		<>
		<MenuItem onClick={handleOpen}><ListItemIcon><AddLocation /></ListItemIcon>Добавить город | офис</MenuItem>
		<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm" aria-labelledby="form-dialog-title">
		<form id="AddCityForm" onSubmit={onSubmit}>
			<DialogTitle id="form-dialog-title">Добавить город</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Выберите город из списка и заполните название локации после.
				</DialogContentText>
				<RussiaCities onChange={(e, value) => setCity(value)} />
				{city && <CheckUTC city={city} UTC={UTC} setUTC={setUTC}/>}
				{city && <CheckCity city={city} setNew={setNew}/>}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" type="button">
					Отменить
				</Button>
				<LoadingButton form="AddCityForm" type="submit" color="primary" loading={load}>
					Добавить
				</LoadingButton>
			</DialogActions>
			</form>
		</Dialog>
		</>
	);
}

const CheckCity = ({city, setNew}) => {
	const [isReady] = useTimeout(1000);

	const state = useAsync(async () => {
		const response = await axios.get(`/cities/check/${city.city_en}`);
		return await response.data;
	}, [city]);

	return(
		<>
		{!isReady() || state.loading ? <Box display="flex" justifyContent="center" m={2}><Box><CircularProgress/></Box></Box> :
		<>
		<List dense={true}>
			{!state.value ? <Typography>Город в базе не обнаружен.</Typography> :
			<>
				<Typography>Обнаружены следующие офисы:</Typography>
				{state?.value.map((item, key) =>
				<ListItem key={key}>
					<ListItemText secondary={item.address || "Неизвестный адресс"}>{key+1}. {item.city}</ListItemText>
				</ListItem>)}
			</>
			}
		</List>
		<TextField
		required
		label="Адрес офиса"
		onChange={(e) => setNew(e.target.value)}
		helperText={`Добавление нового офиса в городе ${city.city}`}
		fullWidth />
		</>
		}
		</>
	);
}

CheckCity.propTypes = {
	city: PropTypes.any,
	setNew: PropTypes.func
}

const CheckUTC = ({city, UTC, setUTC}) => {
	const classes = useStyles();
	const [isReady] = useTimeout(1000);

	const [selected, setSelected] = useState(false);

	const state = useAsync(async () => {
		setSelected(false);
		const response = await axios.get(`https://api.timezonedb.com/v2.1/get-time-zone?key=4BGU6YK8S052&format=json&by=position&lat=${city.lat}&lng=${city.lng}`);
		const result = await response.data;
		result.status == "OK" ? setUTC(result.gmtOffset / 60 / 60) : setSelected(true);
		return result;
	}, [city]);

	return(
		<>
		{!isReady() || state.loading ?
		<Box display="flex" justifyContent="center" m={2}><Box><CircularProgress/></Box></Box> :
		<Box className={classes.utc}>
			<Alert
			severity={state.value?.status == "OK" ? "success" : "error"}
			action={
				<ToggleButton size="small" value="check" selected={selected} onChange={() => setSelected(!selected)}>
					изменить вручную
				</ToggleButton>
			}
			>{state.value?.status == "OK" ? `UTC определен автоматически как ${UTC > 0 ? '+' : '-'}${UTC}` : "UTC не определен автоматически"}</Alert>

			<Collapse in={selected}>

				<TextField
				select
				required
				label="UTC"
				id="selectUTC"
				value={UTC || ""}
				onChange={(e) => setUTC(e.target.value)}
				SelectProps={{
					native: true,
				}}
				helperText="Выберите часовой пояс для города"
				className={classes.selectUtc}
				>
					<option />
					{[0,1,2,3,4,5,6,7,8,9,10,11,12].map(item => <option key={item} value={item}>+{item}</option>)}
				</TextField>

			</Collapse>
		</Box>
		}
		</>
	);
}