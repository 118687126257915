import { types, getParent, flow } from "mobx-state-tree";

export const FinalCurrencyEntry = types.model({
	cityid: types.number,
	Fri: types.number,
	Mon: types.number,
	Sat: types.number,
	Sun: types.number,
	Thu: types.number,
	Tue: types.number,
	Wed: types.number,
	bUSD: types.number,
	sUSD: types.number,
	bEUR: types.number,
	sEUR: types.number,
	bCNY: types.number,
	sCNY: types.number,
	mail: types.number,
	execute: types.string,
	enable: types.number,
	status: types.model({
		active: types.optional(types.boolean, false),
	}),
	// update: types.model({
	// 	id: types.maybeNull(types.number),
	// 	date: types.string,
	// }),
})
.views(self => ({
	get executeDate() {
		if(self.enable) {
			const {utc} = getParent(self).dateUTC;
			// format hh:mm -> new Date()
			var time = self.execute.split(':');
			var ex = new Date();
			ex.setHours(ex.getHours() + utc);
			ex.setHours(time[0], time[1], "00");
			return ex;
		} else return null;
	},

	todayFormat(date) {
		var item;
		switch(date.getDay()) {
			case 1: item = "Mon"; break;
			case 2: item = "Tue"; break;
			case 3: item = "Wed"; break;
			case 4: item = "Thu"; break;
			case 5: item = "Fri"; break;
			case 6: item = "Sat"; break;
			case 0: item = "Sun"; break;
		}

		return item;
	},
}))
.actions(self => ({
	setValue: (type, value) => {
		self[type] = value;
	},

	setTime: (type, value) => {
		if(value) {
			let hours = String(value.getHours()).padStart(2, "0");
			let minutes = String(value.getMinutes()).padStart(2, "0");

			self[type] = `${hours}:${minutes}:00`;
		}
	},

	checkStatus: () => {
		var now = new Date();
		var utc = getParent(self)?.GMT - (now.getTimezoneOffset() / 60 * -1);
			now.setHours(now.getHours() + utc);
		var today = self.todayFormat(now);

		if(self.enable && self[today]) {
			if(now <= self.executeDate) {
				self.status.active = true;
			} else {
				self.status.active = false;
			}
		}
	}
}));