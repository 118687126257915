import React from 'react';
import { TextField } from "@mui/material";

export const Email = (props) => {
	return(
		<TextField
		required
		label="Email"
		type="email"
		inputProps={{
			...props.inputProps,
			autoComplete: "off",
		}}
		{...props}
		/>
	);
}