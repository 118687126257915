import axiosMain from 'axios';

export const cancelAxios = axiosMain.CancelToken.source();

export const axios = axiosMain.create({
	baseURL: '/api',
	cancelToken: cancelAxios.token,
});


