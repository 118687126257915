import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { useStores } from '@store';
import { axios, cancelAxios } from 'api';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

export const Access = observer((props) => {
	const {auth} = useStores();
	const [roles, setRoles] = useState(null);

	useEffect(() => {
		axios.get('/user/allroles').then(res => {
			setRoles(res.data);
		});

		return() => {cancelAxios}
	}, []);

	const CheckAccess = (id) => {
		switch(id) {
			case 1: if(!auth.Developer && auth.id !== 1) return true;break;
			default: return false;
		}
	}

	return(
		!roles ? <Skeleton height={70}/> :
		<FormControl variant="outlined">
			<InputLabel id="accessSelect">Уровень доступа</InputLabel>
			<Select
			labelId="accessSelect"
			autoComplete="off"
			label="Уровень доступа"
			{...props}
			>
				{roles.map(item =>
					<MenuItem key={item.id} value={item.id} disabled={CheckAccess(item.id)}>{item.name}</MenuItem>
				)}
			</Select>
		</FormControl>

	);
});